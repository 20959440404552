.btn {
  overflow: hidden !important;
  text-overflow: ellipsis;
}

button.mat-mdc-button-base.mdc-button.mat-mdc-button,
.mat-mdc-outlined-button.mat-mdc-button-base.mdc-button.mdc-button--outlined,
.mat-mdc-unelevated-button.mat-mdc-button-base.mdc-button.mdc-button--unelevated {
  height: 3rem;
  padding: 0.75rem 1rem;
}

.mat-mdc-outlined-button:not(:disabled, .mat-warn) {
  --mdc-outlined-button-outline-color: var(--Outlined);
}

.mdc-button {
  & mat-icon {
    font-size: 1.75rem !important;
  }
}

.mat-mdc-outlined-button[disabled][disabled],
.mat-mdc-button[disabled][disabled] {
  color: var(--Primary-Disabled);
  border-color: currentColor;
}

button.mdc-button.mat-mdc-outlined-button {
  border: 1px solid var(--Outlined);
  background-color: var(--Surface);
  --mdc-outlined-button-label-text-color: var(--Outlined);

  &:hover {
    background-color: var(--Outlined-Hover);
  }

  &.mat-warn {
    color: var(--Danger);
    border: 1px solid var(--Danger);

    &:hover {
      color: var(--Danger-Hover);
      border: 1px solid var(--Danger-Hover);
    }
  }

  &.mat-accent {
    color: var(--Tonal);
    border: 1px solid var(--Tonal);

    &:hover {
      color: var(--Tonal-Hover);
      border: 1px solid var(--Tonal-Hover);
    }
  }
}

.mdc-button.mat-mdc-unelevated-button {
  color: var(--On-Primary);
  &:hover {
    background-color: var(--Primary-Hover);
  }

  &.mat-accent {
    background-color: var(--Tonal);
    color: var(--On-Tonal);

    &:hover {
      background-color: var(--Tonal-Hover);
    }
  }

  &.mat-warn {
    background-color: var(--Danger);
    color: var(--On-Danger);

    &:hover {
      background-color: var(--Danger-Hover);
    }
  }

  &.mat-mdc-unelevated-button[disabled][disabled] {
    background-color: var(--Primary);
    color: var(--On-Primary);
    opacity: var(--Opacity-Disabled);
  }
}

.mdc-button.mat-mdc-icon-button {
  color: var(--Primary);

  &:hover {
    background-color: var(--Primary-Disabled);
  }

  &.mat-accent {
    background-color: var(--Tonal);
    color: var(--On-Tonal);

    &:hover {
      background-color: var(--Tonal-Hover);
    }
  }

  &.mat-warn {
    background-color: var(--Danger);
    color: var(--On-Danger);

    &:hover {
      background-color: var(--Danger-Hover);
    }
  }
}

.close.mat-mdc-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  line-height: 1.6em;
  min-width: auto;
}

.close.mat-mdc-icon-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  line-height: 1rem;
  min-width: auto;
}

.btn--warning {
  background-color: var(--Status-Error);
  color: var(--On-Status-Error);

  &:hover {
    background-color: var(--Status-Error-Hover);
  }
}
