/*
  0 - 600px: Extra small
  600-959px: Small
  960-1279px: Medium
  1280+: Large,  is where our normal styles apply

  $breakpoint argument choices:visited
  - xs
  - s
  - m
  - l

  1em=16px
  */
html {
  --mat-badge-text-font: Open Sans;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.25rem;
  font-family: Quicksand;
  letter-spacing: -0.4px;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.125rem;
  font-family: Quicksand;
  letter-spacing: -0.4px;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.75rem;
  font-family: Quicksand;
  letter-spacing: -0.4px;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  font-family: Open Sans;
  letter-spacing: -0.2px;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(0.875rem * 0.83) / 1.25rem Open Sans;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(0.875rem * 0.67) / 1.25rem Open Sans;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
  font-family: Quicksand;
  letter-spacing: -0.4px;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  font-family: Open Sans;
  letter-spacing: -0.2px;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 0.75rem;
  font-weight: "Open Sans";
  line-height: 1rem;
  font-family: Open Sans;
  letter-spacing: -0.2px;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font-size: 96px;
  font-weight: 300;
  line-height: 96px;
  font-family: Open Sans;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font-size: 60px;
  font-weight: 300;
  line-height: 60px;
  font-family: Open Sans;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font-size: 48px;
  font-weight: 400;
  line-height: 50px;
  font-family: Open Sans;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: Open Sans;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Open Sans;
  --mat-bottom-sheet-container-text-line-height: 1.25rem;
  --mat-bottom-sheet-container-text-size: 0.875rem;
  --mat-bottom-sheet-container-text-tracking: -0.2px;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-text-font: Open Sans;
  --mat-standard-button-toggle-text-font: Open Sans;
}

html {
  --mat-datepicker-calendar-text-font: Open Sans;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 1rem;
  --mat-datepicker-calendar-body-label-text-weight: 600;
  --mat-datepicker-calendar-period-button-text-size: 1rem;
  --mat-datepicker-calendar-period-button-text-weight: 600;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Quicksand;
  --mat-expansion-header-text-size: 1.25rem;
  --mat-expansion-header-text-weight: 400;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Open Sans;
  --mat-expansion-container-text-line-height: 1.25rem;
  --mat-expansion-container-text-size: 0.875rem;
  --mat-expansion-container-text-tracking: -0.2px;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 0.875rem;
  --mat-grid-list-tile-header-secondary-text-size: 0.75rem;
  --mat-grid-list-tile-footer-primary-text-size: 0.875rem;
  --mat-grid-list-tile-footer-secondary-text-size: 0.75rem;
}

html {
  --mat-stepper-container-text-font: Open Sans;
  --mat-stepper-header-label-text-font: Open Sans;
  --mat-stepper-header-label-text-size: 0.875rem;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 1rem;
  --mat-stepper-header-selected-state-label-text-size: 1rem;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: Quicksand;
  --mat-toolbar-title-text-line-height: 2.125rem;
  --mat-toolbar-title-text-size: 1.75rem;
  --mat-toolbar-title-text-tracking: -0.4px;
  --mat-toolbar-title-text-weight: 600;
}

.mat-tree {
  font-family: Open Sans;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 0.875rem;
}

html {
  --mat-option-label-text-font: Open Sans;
  --mat-option-label-text-line-height: 1.5rem;
  --mat-option-label-text-size: 1rem;
  --mat-option-label-text-tracking: -0.2px;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Open Sans;
  --mat-optgroup-label-text-line-height: 1.5rem;
  --mat-optgroup-label-text-size: 1rem;
  --mat-optgroup-label-text-tracking: -0.2px;
  --mat-optgroup-label-text-weight: 400;
}

.mat-mdc-card {
  --mat-card-title-text-font: Quicksand;
  --mat-card-title-text-line-height: 2.125rem;
  --mat-card-title-text-size: 1.75rem;
  --mat-card-title-text-tracking: -0.4px;
  --mat-card-title-text-weight: 600;
  --mat-card-subtitle-text-font: Quicksand;
  --mat-card-subtitle-text-line-height: 1.5rem;
  --mat-card-subtitle-text-size: 1.25rem;
  --mat-card-subtitle-text-tracking: -0.4px;
  --mat-card-subtitle-text-weight: 400;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Open Sans;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: Open Sans;
  --mdc-plain-tooltip-supporting-text-tracking: -0.2px;
}

html {
  --mdc-filled-text-field-label-text-font: Open Sans;
  --mdc-filled-text-field-label-text-size: 1rem;
  --mdc-filled-text-field-label-text-tracking: -0.2px;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Open Sans;
  --mdc-outlined-text-field-label-text-size: 1rem;
  --mdc-outlined-text-field-label-text-tracking: -0.2px;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Open Sans;
  --mat-form-field-container-text-line-height: 1.5rem;
  --mat-form-field-container-text-size: 1rem;
  --mat-form-field-container-text-tracking: -0.2px;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 1rem;
  --mat-form-field-subscript-text-font: Open Sans;
  --mat-form-field-subscript-text-line-height: 1rem;
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-tracking: -0.2px;
  --mat-form-field-subscript-text-weight: Open Sans;
}

html {
  --mat-select-trigger-text-font: Open Sans;
  --mat-select-trigger-text-line-height: 1.5rem;
  --mat-select-trigger-text-size: 1rem;
  --mat-select-trigger-text-tracking: -0.2px;
  --mat-select-trigger-text-weight: 400;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Quicksand;
  --mdc-dialog-subhead-line-height: 2.125rem;
  --mdc-dialog-subhead-size: 1.75rem;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-subhead-tracking: -0.4px;
  --mdc-dialog-supporting-text-font: Open Sans;
  --mdc-dialog-supporting-text-line-height: 1.5rem;
  --mdc-dialog-supporting-text-size: 1rem;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: -0.2px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Open Sans;
  --mdc-chip-label-text-line-height: 1.25rem;
  --mdc-chip-label-text-size: 0.875rem;
  --mdc-chip-label-text-tracking: -0.2px;
  --mdc-chip-label-text-weight: 400;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-font: Open Sans;
  --mat-slide-toggle-label-text-size: 0.875rem;
  --mat-slide-toggle-label-text-tracking: -0.2px;
  --mat-slide-toggle-label-text-line-height: 1.25rem;
  --mat-slide-toggle-label-text-weight: 400;
}
.mat-mdc-slide-toggle .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.2px);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Quicksand;
  --mdc-slider-label-label-text-size: 1.25rem;
  --mdc-slider-label-label-text-line-height: 1.5rem;
  --mdc-slider-label-label-text-tracking: -0.4px;
  --mdc-slider-label-label-text-weight: 400;
}

html {
  --mat-menu-item-label-text-font: Open Sans;
  --mat-menu-item-label-text-size: 1rem;
  --mat-menu-item-label-text-tracking: -0.2px;
  --mat-menu-item-label-text-line-height: 1.5rem;
  --mat-menu-item-label-text-weight: 400;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Open Sans;
  --mdc-list-list-item-label-text-line-height: 1.5rem;
  --mdc-list-list-item-label-text-size: 1rem;
  --mdc-list-list-item-label-text-tracking: -0.2px;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Open Sans;
  --mdc-list-list-item-supporting-text-line-height: 1.25rem;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: -0.2px;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Open Sans;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.75rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: -0.2px;
  --mdc-list-list-item-trailing-supporting-text-weight: Open Sans;
}

.mdc-list-group__subheader {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.75rem;
  font-family: Quicksand;
  letter-spacing: -0.4px;
}

html {
  --mat-paginator-container-text-font: Open Sans;
  --mat-paginator-container-text-line-height: 1rem;
  --mat-paginator-container-text-size: 0.75rem;
  --mat-paginator-container-text-tracking: -0.2px;
  --mat-paginator-container-text-weight: Open Sans;
  --mat-paginator-select-trigger-text-size: 0.75rem;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Open Sans;
  --mat-tab-header-label-text-size: 1rem;
  --mat-tab-header-label-text-tracking: -0.2px;
  --mat-tab-header-label-text-line-height: 1.5rem;
  --mat-tab-header-label-text-weight: 600;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.2px);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-button-font-size, 1rem);
  line-height: var(--mdc-typography-button-line-height, 1.5rem);
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: var(--mdc-typography-button-letter-spacing, -0.2px);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-button-font-size, 1rem);
  line-height: var(--mdc-typography-button-line-height, 1.5rem);
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: var(--mdc-typography-button-letter-spacing, -0.2px);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-extended-fab {
  --mdc-extended-fab-label-text-font: Open Sans;
  --mdc-extended-fab-label-text-size: 1rem;
  --mdc-extended-fab-label-text-tracking: -0.2px;
  --mdc-extended-fab-label-text-weight: 600;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Open Sans;
  --mdc-snackbar-supporting-text-line-height: 1.25rem;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Quicksand;
  --mat-table-header-headline-line-height: 1.5rem;
  --mat-table-header-headline-size: 1.25rem;
  --mat-table-header-headline-weight: 400;
  --mat-table-header-headline-tracking: -0.4px;
  --mat-table-row-item-label-text-font: Open Sans;
  --mat-table-row-item-label-text-line-height: 1.25rem;
  --mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: -0.2px;
  --mat-table-footer-supporting-text-font: Open Sans;
  --mat-table-footer-supporting-text-line-height: 1.25rem;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: -0.2px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

@media only screen and (max-width: 80em) {
  html {
    --mat-badge-text-font: Open Sans;
    --mat-badge-text-size: 12px;
    --mat-badge-text-weight: 600;
    --mat-badge-small-size-text-size: 9px;
    --mat-badge-large-size-text-size: 24px;
  }
  .mat-h1,
  .mat-headline-5,
  .mat-typography .mat-h1,
  .mat-typography .mat-headline-5,
  .mat-typography h1 {
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 2rem;
    font-family: Quicksand;
    letter-spacing: -0.4px;
    margin: 0 0 16px;
  }
  .mat-h2,
  .mat-headline-6,
  .mat-typography .mat-h2,
  .mat-typography .mat-headline-6,
  .mat-typography h2 {
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 1.875rem;
    font-family: Quicksand;
    letter-spacing: -0.4px;
    margin: 0 0 16px;
  }
  .mat-h3,
  .mat-subtitle-1,
  .mat-typography .mat-h3,
  .mat-typography .mat-subtitle-1,
  .mat-typography h3 {
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.625rem;
    font-family: Quicksand;
    letter-spacing: -0.4px;
    margin: 0 0 16px;
  }
  .mat-h4,
  .mat-body-1,
  .mat-typography .mat-h4,
  .mat-typography .mat-body-1,
  .mat-typography h4 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    font-family: Open Sans;
    letter-spacing: -0.2px;
    margin: 0 0 16px;
  }
  .mat-h5,
  .mat-typography .mat-h5,
  .mat-typography h5 {
    font: 400 calc(0.875rem * 0.83) / 1.25rem Open Sans;
    margin: 0 0 12px;
  }
  .mat-h6,
  .mat-typography .mat-h6,
  .mat-typography h6 {
    font: 400 calc(0.875rem * 0.67) / 1.25rem Open Sans;
    margin: 0 0 12px;
  }
  .mat-body-strong,
  .mat-subtitle-2,
  .mat-typography .mat-body-strong,
  .mat-typography .mat-subtitle-2 {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    font-family: Quicksand;
    letter-spacing: -0.4px;
  }
  .mat-body,
  .mat-body-2,
  .mat-typography .mat-body,
  .mat-typography .mat-body-2,
  .mat-typography {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    font-family: Open Sans;
    letter-spacing: -0.2px;
  }
  .mat-body p,
  .mat-body-2 p,
  .mat-typography .mat-body p,
  .mat-typography .mat-body-2 p,
  .mat-typography p {
    margin: 0 0 12px;
  }
  .mat-small,
  .mat-caption,
  .mat-typography .mat-small,
  .mat-typography .mat-caption {
    font-size: 0.75rem;
    font-weight: "Open Sans";
    line-height: 1rem;
    font-family: Open Sans;
    letter-spacing: -0.2px;
  }
  .mat-headline-1,
  .mat-typography .mat-headline-1 {
    font-size: 96px;
    font-weight: 300;
    line-height: 96px;
    font-family: Open Sans;
    letter-spacing: -0.015625em;
    margin: 0 0 56px;
  }
  .mat-headline-2,
  .mat-typography .mat-headline-2 {
    font-size: 60px;
    font-weight: 300;
    line-height: 60px;
    font-family: Open Sans;
    letter-spacing: -0.0083333333em;
    margin: 0 0 64px;
  }
  .mat-headline-3,
  .mat-typography .mat-headline-3 {
    font-size: 48px;
    font-weight: 400;
    line-height: 50px;
    font-family: Open Sans;
    letter-spacing: normal;
    margin: 0 0 64px;
  }
  .mat-headline-4,
  .mat-typography .mat-headline-4 {
    font-size: 34px;
    font-weight: 400;
    line-height: 40px;
    font-family: Open Sans;
    letter-spacing: 0.0073529412em;
    margin: 0 0 64px;
  }
  html {
    --mat-bottom-sheet-container-text-font: Open Sans;
    --mat-bottom-sheet-container-text-line-height: 1.25rem;
    --mat-bottom-sheet-container-text-size: 0.875rem;
    --mat-bottom-sheet-container-text-tracking: -0.2px;
    --mat-bottom-sheet-container-text-weight: 400;
  }
  html {
    --mat-legacy-button-toggle-text-font: Open Sans;
    --mat-standard-button-toggle-text-font: Open Sans;
  }
  html {
    --mat-datepicker-calendar-text-font: Open Sans;
    --mat-datepicker-calendar-text-size: 13px;
    --mat-datepicker-calendar-body-label-text-size: 1rem;
    --mat-datepicker-calendar-body-label-text-weight: 600;
    --mat-datepicker-calendar-period-button-text-size: 1rem;
    --mat-datepicker-calendar-period-button-text-weight: 600;
    --mat-datepicker-calendar-header-text-size: 11px;
    --mat-datepicker-calendar-header-text-weight: 400;
  }
  html {
    --mat-expansion-header-text-font: Quicksand;
    --mat-expansion-header-text-size: 1.125rem;
    --mat-expansion-header-text-weight: 400;
    --mat-expansion-header-text-line-height: inherit;
    --mat-expansion-header-text-tracking: inherit;
    --mat-expansion-container-text-font: Open Sans;
    --mat-expansion-container-text-line-height: 1.25rem;
    --mat-expansion-container-text-size: 0.875rem;
    --mat-expansion-container-text-tracking: -0.2px;
    --mat-expansion-container-text-weight: 400;
  }
  html {
    --mat-grid-list-tile-header-primary-text-size: 0.875rem;
    --mat-grid-list-tile-header-secondary-text-size: 0.75rem;
    --mat-grid-list-tile-footer-primary-text-size: 0.875rem;
    --mat-grid-list-tile-footer-secondary-text-size: 0.75rem;
  }
  html {
    --mat-stepper-container-text-font: Open Sans;
    --mat-stepper-header-label-text-font: Open Sans;
    --mat-stepper-header-label-text-size: 0.875rem;
    --mat-stepper-header-label-text-weight: 400;
    --mat-stepper-header-error-state-label-text-size: 1rem;
    --mat-stepper-header-selected-state-label-text-size: 1rem;
    --mat-stepper-header-selected-state-label-text-weight: 400;
  }
  html {
    --mat-toolbar-title-text-font: Quicksand;
    --mat-toolbar-title-text-line-height: 1.875rem;
    --mat-toolbar-title-text-size: 1.625rem;
    --mat-toolbar-title-text-tracking: -0.4px;
    --mat-toolbar-title-text-weight: 600;
  }
  .mat-tree {
    font-family: Open Sans;
  }
  .mat-tree-node,
  .mat-nested-tree-node {
    font-weight: 400;
    font-size: 0.875rem;
  }
  html {
    --mat-option-label-text-font: Open Sans;
    --mat-option-label-text-line-height: 1.5rem;
    --mat-option-label-text-size: 1rem;
    --mat-option-label-text-tracking: -0.2px;
    --mat-option-label-text-weight: 400;
  }
  html {
    --mat-optgroup-label-text-font: Open Sans;
    --mat-optgroup-label-text-line-height: 1.5rem;
    --mat-optgroup-label-text-size: 1rem;
    --mat-optgroup-label-text-tracking: -0.2px;
    --mat-optgroup-label-text-weight: 400;
  }
  .mat-mdc-card {
    --mat-card-title-text-font: Quicksand;
    --mat-card-title-text-line-height: 1.875rem;
    --mat-card-title-text-size: 1.625rem;
    --mat-card-title-text-tracking: -0.4px;
    --mat-card-title-text-weight: 600;
    --mat-card-subtitle-text-font: Quicksand;
    --mat-card-subtitle-text-line-height: 1.5rem;
    --mat-card-subtitle-text-size: 1.125rem;
    --mat-card-subtitle-text-tracking: -0.4px;
    --mat-card-subtitle-text-weight: 400;
  }
  .mat-mdc-tooltip {
    --mdc-plain-tooltip-supporting-text-font: Open Sans;
    --mdc-plain-tooltip-supporting-text-size: 0.75rem;
    --mdc-plain-tooltip-supporting-text-weight: Open Sans;
    --mdc-plain-tooltip-supporting-text-tracking: -0.2px;
  }
  html {
    --mdc-filled-text-field-label-text-font: Open Sans;
    --mdc-filled-text-field-label-text-size: 1rem;
    --mdc-filled-text-field-label-text-tracking: -0.2px;
    --mdc-filled-text-field-label-text-weight: 400;
    --mdc-outlined-text-field-label-text-font: Open Sans;
    --mdc-outlined-text-field-label-text-size: 1rem;
    --mdc-outlined-text-field-label-text-tracking: -0.2px;
    --mdc-outlined-text-field-label-text-weight: 400;
    --mat-form-field-container-text-font: Open Sans;
    --mat-form-field-container-text-line-height: 1.5rem;
    --mat-form-field-container-text-size: 1rem;
    --mat-form-field-container-text-tracking: -0.2px;
    --mat-form-field-container-text-weight: 400;
    --mat-form-field-outlined-label-text-populated-size: 1rem;
    --mat-form-field-subscript-text-font: Open Sans;
    --mat-form-field-subscript-text-line-height: 1rem;
    --mat-form-field-subscript-text-size: 0.75rem;
    --mat-form-field-subscript-text-tracking: -0.2px;
    --mat-form-field-subscript-text-weight: Open Sans;
  }
  html {
    --mat-select-trigger-text-font: Open Sans;
    --mat-select-trigger-text-line-height: 1.5rem;
    --mat-select-trigger-text-size: 1rem;
    --mat-select-trigger-text-tracking: -0.2px;
    --mat-select-trigger-text-weight: 400;
  }
  .mat-mdc-dialog-container {
    --mdc-dialog-subhead-font: Quicksand;
    --mdc-dialog-subhead-line-height: 1.875rem;
    --mdc-dialog-subhead-size: 1.625rem;
    --mdc-dialog-subhead-weight: 600;
    --mdc-dialog-subhead-tracking: -0.4px;
    --mdc-dialog-supporting-text-font: Open Sans;
    --mdc-dialog-supporting-text-line-height: 1.5rem;
    --mdc-dialog-supporting-text-size: 1rem;
    --mdc-dialog-supporting-text-weight: 400;
    --mdc-dialog-supporting-text-tracking: -0.2px;
  }
  .mat-mdc-standard-chip {
    --mdc-chip-label-text-font: Open Sans;
    --mdc-chip-label-text-line-height: 1.25rem;
    --mdc-chip-label-text-size: 0.875rem;
    --mdc-chip-label-text-tracking: -0.2px;
    --mdc-chip-label-text-weight: 400;
  }
  .mat-mdc-slide-toggle {
    --mat-slide-toggle-label-text-font: Open Sans;
    --mat-slide-toggle-label-text-size: 0.875rem;
    --mat-slide-toggle-label-text-tracking: -0.2px;
    --mat-slide-toggle-label-text-line-height: 1.25rem;
    --mat-slide-toggle-label-text-weight: 400;
  }
  .mat-mdc-slide-toggle .mdc-form-field {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: Roboto, sans-serif;
    /* @alternate */
    font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
    font-size: 0.875rem;
    /* @alternate */
    font-size: var(--mdc-typography-body2-font-size, 0.875rem);
    line-height: 1.25rem;
    /* @alternate */
    line-height: var(--mdc-typography-body2-line-height, 1.25rem);
    font-weight: 400;
    /* @alternate */
    font-weight: var(--mdc-typography-body2-font-weight, 400);
    letter-spacing: 0.0178571429em;
    /* @alternate */
    letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
    text-decoration: inherit;
    /* @alternate */
    text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
    text-transform: inherit;
    /* @alternate */
    text-transform: var(--mdc-typography-body2-text-transform, inherit);
  }
  .mat-mdc-radio-button .mdc-form-field {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
    font-size: var(--mdc-typography-body2-font-size, 0.875rem);
    line-height: var(--mdc-typography-body2-line-height, 1.25rem);
    font-weight: var(--mdc-typography-body2-font-weight, 400);
    letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.2px);
    text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
    text-transform: var(--mdc-typography-body2-text-transform, none);
  }
  .mat-mdc-slider {
    --mdc-slider-label-label-text-font: Quicksand;
    --mdc-slider-label-label-text-size: 1.125rem;
    --mdc-slider-label-label-text-line-height: 1.5rem;
    --mdc-slider-label-label-text-tracking: -0.4px;
    --mdc-slider-label-label-text-weight: 400;
  }
  html {
    --mat-menu-item-label-text-font: Open Sans;
    --mat-menu-item-label-text-size: 1rem;
    --mat-menu-item-label-text-tracking: -0.2px;
    --mat-menu-item-label-text-line-height: 1.5rem;
    --mat-menu-item-label-text-weight: 400;
  }
  .mat-mdc-list-base {
    --mdc-list-list-item-label-text-font: Open Sans;
    --mdc-list-list-item-label-text-line-height: 1.5rem;
    --mdc-list-list-item-label-text-size: 1rem;
    --mdc-list-list-item-label-text-tracking: -0.2px;
    --mdc-list-list-item-label-text-weight: 400;
    --mdc-list-list-item-supporting-text-font: Open Sans;
    --mdc-list-list-item-supporting-text-line-height: 1.25rem;
    --mdc-list-list-item-supporting-text-size: 0.875rem;
    --mdc-list-list-item-supporting-text-tracking: -0.2px;
    --mdc-list-list-item-supporting-text-weight: 400;
    --mdc-list-list-item-trailing-supporting-text-font: Open Sans;
    --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
    --mdc-list-list-item-trailing-supporting-text-size: 0.75rem;
    --mdc-list-list-item-trailing-supporting-text-tracking: -0.2px;
    --mdc-list-list-item-trailing-supporting-text-weight: Open Sans;
  }
  .mdc-list-group__subheader {
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.625rem;
    font-family: Quicksand;
    letter-spacing: -0.4px;
  }
  html {
    --mat-paginator-container-text-font: Open Sans;
    --mat-paginator-container-text-line-height: 1rem;
    --mat-paginator-container-text-size: 0.75rem;
    --mat-paginator-container-text-tracking: -0.2px;
    --mat-paginator-container-text-weight: Open Sans;
    --mat-paginator-select-trigger-text-size: 0.75rem;
  }
  .mat-mdc-tab-header {
    --mat-tab-header-label-text-font: Open Sans;
    --mat-tab-header-label-text-size: 1rem;
    --mat-tab-header-label-text-tracking: -0.2px;
    --mat-tab-header-label-text-line-height: 1.5rem;
    --mat-tab-header-label-text-weight: 600;
  }
  .mat-mdc-checkbox .mdc-form-field {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
    font-size: var(--mdc-typography-body2-font-size, 0.875rem);
    line-height: var(--mdc-typography-body2-line-height, 1.25rem);
    font-weight: var(--mdc-typography-body2-font-weight, 400);
    letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.2px);
    text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
    text-transform: var(--mdc-typography-body2-text-transform, none);
  }
  .mdc-button {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans));
    font-size: var(--mdc-typography-button-font-size, 1rem);
    line-height: var(--mdc-typography-button-line-height, 1.5rem);
    font-weight: var(--mdc-typography-button-font-weight, 600);
    letter-spacing: var(--mdc-typography-button-letter-spacing, -0.2px);
    text-decoration: var(--mdc-typography-button-text-decoration, none);
    text-transform: var(--mdc-typography-button-text-transform, none);
  }
  .mdc-fab--extended {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans));
    font-size: var(--mdc-typography-button-font-size, 1rem);
    line-height: var(--mdc-typography-button-line-height, 1.5rem);
    font-weight: var(--mdc-typography-button-font-weight, 600);
    letter-spacing: var(--mdc-typography-button-letter-spacing, -0.2px);
    text-decoration: var(--mdc-typography-button-text-decoration, none);
    text-transform: var(--mdc-typography-button-text-transform, none);
  }
  .mat-mdc-extended-fab {
    --mdc-extended-fab-label-text-font: Open Sans;
    --mdc-extended-fab-label-text-size: 1rem;
    --mdc-extended-fab-label-text-tracking: -0.2px;
    --mdc-extended-fab-label-text-weight: 600;
  }
  .mat-mdc-snack-bar-container {
    --mdc-snackbar-supporting-text-font: Open Sans;
    --mdc-snackbar-supporting-text-line-height: 1.25rem;
    --mdc-snackbar-supporting-text-size: 0.875rem;
    --mdc-snackbar-supporting-text-weight: 400;
  }
  html {
    --mat-table-header-headline-font: Quicksand;
    --mat-table-header-headline-line-height: 1.5rem;
    --mat-table-header-headline-size: 1.125rem;
    --mat-table-header-headline-weight: 400;
    --mat-table-header-headline-tracking: -0.4px;
    --mat-table-row-item-label-text-font: Open Sans;
    --mat-table-row-item-label-text-line-height: 1.25rem;
    --mat-table-row-item-label-text-size: 0.875rem;
    --mat-table-row-item-label-text-weight: 400;
    --mat-table-row-item-label-text-tracking: -0.2px;
    --mat-table-footer-supporting-text-font: Open Sans;
    --mat-table-footer-supporting-text-line-height: 1.25rem;
    --mat-table-footer-supporting-text-size: 0.875rem;
    --mat-table-footer-supporting-text-weight: 400;
    --mat-table-footer-supporting-text-tracking: -0.2px;
  }
}
@media only screen and (max-width: 60em) {
  html {
    --mat-badge-text-font: Open Sans;
    --mat-badge-text-size: 12px;
    --mat-badge-text-weight: 600;
    --mat-badge-small-size-text-size: 9px;
    --mat-badge-large-size-text-size: 24px;
  }
  .mat-h1,
  .mat-headline-5,
  .mat-typography .mat-h1,
  .mat-typography .mat-headline-5,
  .mat-typography h1 {
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.875rem;
    font-family: Quicksand;
    letter-spacing: -0.4px;
    margin: 0 0 16px;
  }
  .mat-h2,
  .mat-headline-6,
  .mat-typography .mat-h2,
  .mat-typography .mat-headline-6,
  .mat-typography h2 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    font-family: Quicksand;
    letter-spacing: -0.4px;
    margin: 0 0 16px;
  }
  .mat-h3,
  .mat-subtitle-1,
  .mat-typography .mat-h3,
  .mat-typography .mat-subtitle-1,
  .mat-typography h3 {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    font-family: Quicksand;
    letter-spacing: -0.4px;
    margin: 0 0 16px;
  }
  .mat-h4,
  .mat-body-1,
  .mat-typography .mat-h4,
  .mat-typography .mat-body-1,
  .mat-typography h4 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    font-family: Open Sans;
    letter-spacing: -0.2px;
    margin: 0 0 16px;
  }
  .mat-h5,
  .mat-typography .mat-h5,
  .mat-typography h5 {
    font: 400 calc(0.875rem * 0.83) / 1.25rem Open Sans;
    margin: 0 0 12px;
  }
  .mat-h6,
  .mat-typography .mat-h6,
  .mat-typography h6 {
    font: 400 calc(0.875rem * 0.67) / 1.25rem Open Sans;
    margin: 0 0 12px;
  }
  .mat-body-strong,
  .mat-subtitle-2,
  .mat-typography .mat-body-strong,
  .mat-typography .mat-subtitle-2 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    font-family: Quicksand;
    letter-spacing: -0.4px;
  }
  .mat-body,
  .mat-body-2,
  .mat-typography .mat-body,
  .mat-typography .mat-body-2,
  .mat-typography {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    font-family: Open Sans;
    letter-spacing: -0.2px;
  }
  .mat-body p,
  .mat-body-2 p,
  .mat-typography .mat-body p,
  .mat-typography .mat-body-2 p,
  .mat-typography p {
    margin: 0 0 12px;
  }
  .mat-small,
  .mat-caption,
  .mat-typography .mat-small,
  .mat-typography .mat-caption {
    font-size: 0.75rem;
    font-weight: "Open Sans";
    line-height: 1rem;
    font-family: Open Sans;
    letter-spacing: -0.2px;
  }
  .mat-headline-1,
  .mat-typography .mat-headline-1 {
    font-size: 96px;
    font-weight: 300;
    line-height: 96px;
    font-family: Open Sans;
    letter-spacing: -0.015625em;
    margin: 0 0 56px;
  }
  .mat-headline-2,
  .mat-typography .mat-headline-2 {
    font-size: 60px;
    font-weight: 300;
    line-height: 60px;
    font-family: Open Sans;
    letter-spacing: -0.0083333333em;
    margin: 0 0 64px;
  }
  .mat-headline-3,
  .mat-typography .mat-headline-3 {
    font-size: 48px;
    font-weight: 400;
    line-height: 50px;
    font-family: Open Sans;
    letter-spacing: normal;
    margin: 0 0 64px;
  }
  .mat-headline-4,
  .mat-typography .mat-headline-4 {
    font-size: 34px;
    font-weight: 400;
    line-height: 40px;
    font-family: Open Sans;
    letter-spacing: 0.0073529412em;
    margin: 0 0 64px;
  }
  html {
    --mat-bottom-sheet-container-text-font: Open Sans;
    --mat-bottom-sheet-container-text-line-height: 1.25rem;
    --mat-bottom-sheet-container-text-size: 0.875rem;
    --mat-bottom-sheet-container-text-tracking: -0.2px;
    --mat-bottom-sheet-container-text-weight: 400;
  }
  html {
    --mat-legacy-button-toggle-text-font: Open Sans;
    --mat-standard-button-toggle-text-font: Open Sans;
  }
  html {
    --mat-datepicker-calendar-text-font: Open Sans;
    --mat-datepicker-calendar-text-size: 13px;
    --mat-datepicker-calendar-body-label-text-size: 1rem;
    --mat-datepicker-calendar-body-label-text-weight: 600;
    --mat-datepicker-calendar-period-button-text-size: 1rem;
    --mat-datepicker-calendar-period-button-text-weight: 600;
    --mat-datepicker-calendar-header-text-size: 11px;
    --mat-datepicker-calendar-header-text-weight: 400;
  }
  html {
    --mat-expansion-header-text-font: Quicksand;
    --mat-expansion-header-text-size: 1rem;
    --mat-expansion-header-text-weight: 400;
    --mat-expansion-header-text-line-height: inherit;
    --mat-expansion-header-text-tracking: inherit;
    --mat-expansion-container-text-font: Open Sans;
    --mat-expansion-container-text-line-height: 1.25rem;
    --mat-expansion-container-text-size: 0.875rem;
    --mat-expansion-container-text-tracking: -0.2px;
    --mat-expansion-container-text-weight: 400;
  }
  html {
    --mat-grid-list-tile-header-primary-text-size: 0.875rem;
    --mat-grid-list-tile-header-secondary-text-size: 0.75rem;
    --mat-grid-list-tile-footer-primary-text-size: 0.875rem;
    --mat-grid-list-tile-footer-secondary-text-size: 0.75rem;
  }
  html {
    --mat-stepper-container-text-font: Open Sans;
    --mat-stepper-header-label-text-font: Open Sans;
    --mat-stepper-header-label-text-size: 0.875rem;
    --mat-stepper-header-label-text-weight: 400;
    --mat-stepper-header-error-state-label-text-size: 1rem;
    --mat-stepper-header-selected-state-label-text-size: 1rem;
    --mat-stepper-header-selected-state-label-text-weight: 400;
  }
  html {
    --mat-toolbar-title-text-font: Quicksand;
    --mat-toolbar-title-text-line-height: 1.5rem;
    --mat-toolbar-title-text-size: 1.25rem;
    --mat-toolbar-title-text-tracking: -0.4px;
    --mat-toolbar-title-text-weight: 700;
  }
  .mat-tree {
    font-family: Open Sans;
  }
  .mat-tree-node,
  .mat-nested-tree-node {
    font-weight: 400;
    font-size: 0.875rem;
  }
  html {
    --mat-option-label-text-font: Open Sans;
    --mat-option-label-text-line-height: 1.5rem;
    --mat-option-label-text-size: 1rem;
    --mat-option-label-text-tracking: -0.2px;
    --mat-option-label-text-weight: 400;
  }
  html {
    --mat-optgroup-label-text-font: Open Sans;
    --mat-optgroup-label-text-line-height: 1.5rem;
    --mat-optgroup-label-text-size: 1rem;
    --mat-optgroup-label-text-tracking: -0.2px;
    --mat-optgroup-label-text-weight: 400;
  }
  .mat-mdc-card {
    --mat-card-title-text-font: Quicksand;
    --mat-card-title-text-line-height: 1.5rem;
    --mat-card-title-text-size: 1.25rem;
    --mat-card-title-text-tracking: -0.4px;
    --mat-card-title-text-weight: 700;
    --mat-card-subtitle-text-font: Quicksand;
    --mat-card-subtitle-text-line-height: 1.5rem;
    --mat-card-subtitle-text-size: 1rem;
    --mat-card-subtitle-text-tracking: -0.4px;
    --mat-card-subtitle-text-weight: 400;
  }
  .mat-mdc-tooltip {
    --mdc-plain-tooltip-supporting-text-font: Open Sans;
    --mdc-plain-tooltip-supporting-text-size: 0.75rem;
    --mdc-plain-tooltip-supporting-text-weight: Open Sans;
    --mdc-plain-tooltip-supporting-text-tracking: -0.2px;
  }
  html {
    --mdc-filled-text-field-label-text-font: Open Sans;
    --mdc-filled-text-field-label-text-size: 1rem;
    --mdc-filled-text-field-label-text-tracking: -0.2px;
    --mdc-filled-text-field-label-text-weight: 400;
    --mdc-outlined-text-field-label-text-font: Open Sans;
    --mdc-outlined-text-field-label-text-size: 1rem;
    --mdc-outlined-text-field-label-text-tracking: -0.2px;
    --mdc-outlined-text-field-label-text-weight: 400;
    --mat-form-field-container-text-font: Open Sans;
    --mat-form-field-container-text-line-height: 1.5rem;
    --mat-form-field-container-text-size: 1rem;
    --mat-form-field-container-text-tracking: -0.2px;
    --mat-form-field-container-text-weight: 400;
    --mat-form-field-outlined-label-text-populated-size: 1rem;
    --mat-form-field-subscript-text-font: Open Sans;
    --mat-form-field-subscript-text-line-height: 1rem;
    --mat-form-field-subscript-text-size: 0.75rem;
    --mat-form-field-subscript-text-tracking: -0.2px;
    --mat-form-field-subscript-text-weight: Open Sans;
  }
  html {
    --mat-select-trigger-text-font: Open Sans;
    --mat-select-trigger-text-line-height: 1.5rem;
    --mat-select-trigger-text-size: 1rem;
    --mat-select-trigger-text-tracking: -0.2px;
    --mat-select-trigger-text-weight: 400;
  }
  .mat-mdc-dialog-container {
    --mdc-dialog-subhead-font: Quicksand;
    --mdc-dialog-subhead-line-height: 1.5rem;
    --mdc-dialog-subhead-size: 1.25rem;
    --mdc-dialog-subhead-weight: 700;
    --mdc-dialog-subhead-tracking: -0.4px;
    --mdc-dialog-supporting-text-font: Open Sans;
    --mdc-dialog-supporting-text-line-height: 1.5rem;
    --mdc-dialog-supporting-text-size: 1rem;
    --mdc-dialog-supporting-text-weight: 400;
    --mdc-dialog-supporting-text-tracking: -0.2px;
  }
  .mat-mdc-standard-chip {
    --mdc-chip-label-text-font: Open Sans;
    --mdc-chip-label-text-line-height: 1.25rem;
    --mdc-chip-label-text-size: 0.875rem;
    --mdc-chip-label-text-tracking: -0.2px;
    --mdc-chip-label-text-weight: 400;
  }
  .mat-mdc-slide-toggle {
    --mat-slide-toggle-label-text-font: Open Sans;
    --mat-slide-toggle-label-text-size: 0.875rem;
    --mat-slide-toggle-label-text-tracking: -0.2px;
    --mat-slide-toggle-label-text-line-height: 1.25rem;
    --mat-slide-toggle-label-text-weight: 400;
  }
  .mat-mdc-slide-toggle .mdc-form-field {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: Roboto, sans-serif;
    /* @alternate */
    font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
    font-size: 0.875rem;
    /* @alternate */
    font-size: var(--mdc-typography-body2-font-size, 0.875rem);
    line-height: 1.25rem;
    /* @alternate */
    line-height: var(--mdc-typography-body2-line-height, 1.25rem);
    font-weight: 400;
    /* @alternate */
    font-weight: var(--mdc-typography-body2-font-weight, 400);
    letter-spacing: 0.0178571429em;
    /* @alternate */
    letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
    text-decoration: inherit;
    /* @alternate */
    text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
    text-transform: inherit;
    /* @alternate */
    text-transform: var(--mdc-typography-body2-text-transform, inherit);
  }
  .mat-mdc-radio-button .mdc-form-field {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
    font-size: var(--mdc-typography-body2-font-size, 0.875rem);
    line-height: var(--mdc-typography-body2-line-height, 1.25rem);
    font-weight: var(--mdc-typography-body2-font-weight, 400);
    letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.2px);
    text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
    text-transform: var(--mdc-typography-body2-text-transform, none);
  }
  .mat-mdc-slider {
    --mdc-slider-label-label-text-font: Quicksand;
    --mdc-slider-label-label-text-size: 1rem;
    --mdc-slider-label-label-text-line-height: 1.5rem;
    --mdc-slider-label-label-text-tracking: -0.4px;
    --mdc-slider-label-label-text-weight: 400;
  }
  html {
    --mat-menu-item-label-text-font: Open Sans;
    --mat-menu-item-label-text-size: 1rem;
    --mat-menu-item-label-text-tracking: -0.2px;
    --mat-menu-item-label-text-line-height: 1.5rem;
    --mat-menu-item-label-text-weight: 400;
  }
  .mat-mdc-list-base {
    --mdc-list-list-item-label-text-font: Open Sans;
    --mdc-list-list-item-label-text-line-height: 1.5rem;
    --mdc-list-list-item-label-text-size: 1rem;
    --mdc-list-list-item-label-text-tracking: -0.2px;
    --mdc-list-list-item-label-text-weight: 400;
    --mdc-list-list-item-supporting-text-font: Open Sans;
    --mdc-list-list-item-supporting-text-line-height: 1.25rem;
    --mdc-list-list-item-supporting-text-size: 0.875rem;
    --mdc-list-list-item-supporting-text-tracking: -0.2px;
    --mdc-list-list-item-supporting-text-weight: 400;
    --mdc-list-list-item-trailing-supporting-text-font: Open Sans;
    --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
    --mdc-list-list-item-trailing-supporting-text-size: 0.75rem;
    --mdc-list-list-item-trailing-supporting-text-tracking: -0.2px;
    --mdc-list-list-item-trailing-supporting-text-weight: Open Sans;
  }
  .mdc-list-group__subheader {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    font-family: Quicksand;
    letter-spacing: -0.4px;
  }
  html {
    --mat-paginator-container-text-font: Open Sans;
    --mat-paginator-container-text-line-height: 1rem;
    --mat-paginator-container-text-size: 0.75rem;
    --mat-paginator-container-text-tracking: -0.2px;
    --mat-paginator-container-text-weight: Open Sans;
    --mat-paginator-select-trigger-text-size: 0.75rem;
  }
  .mat-mdc-tab-header {
    --mat-tab-header-label-text-font: Open Sans;
    --mat-tab-header-label-text-size: 1rem;
    --mat-tab-header-label-text-tracking: -0.2px;
    --mat-tab-header-label-text-line-height: 1.5rem;
    --mat-tab-header-label-text-weight: 600;
  }
  .mat-mdc-checkbox .mdc-form-field {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
    font-size: var(--mdc-typography-body2-font-size, 0.875rem);
    line-height: var(--mdc-typography-body2-line-height, 1.25rem);
    font-weight: var(--mdc-typography-body2-font-weight, 400);
    letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.2px);
    text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
    text-transform: var(--mdc-typography-body2-text-transform, none);
  }
  .mdc-button {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans));
    font-size: var(--mdc-typography-button-font-size, 1rem);
    line-height: var(--mdc-typography-button-line-height, 1.5rem);
    font-weight: var(--mdc-typography-button-font-weight, 600);
    letter-spacing: var(--mdc-typography-button-letter-spacing, -0.2px);
    text-decoration: var(--mdc-typography-button-text-decoration, none);
    text-transform: var(--mdc-typography-button-text-transform, none);
  }
  .mdc-fab--extended {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans));
    font-size: var(--mdc-typography-button-font-size, 1rem);
    line-height: var(--mdc-typography-button-line-height, 1.5rem);
    font-weight: var(--mdc-typography-button-font-weight, 600);
    letter-spacing: var(--mdc-typography-button-letter-spacing, -0.2px);
    text-decoration: var(--mdc-typography-button-text-decoration, none);
    text-transform: var(--mdc-typography-button-text-transform, none);
  }
  .mat-mdc-extended-fab {
    --mdc-extended-fab-label-text-font: Open Sans;
    --mdc-extended-fab-label-text-size: 1rem;
    --mdc-extended-fab-label-text-tracking: -0.2px;
    --mdc-extended-fab-label-text-weight: 600;
  }
  .mat-mdc-snack-bar-container {
    --mdc-snackbar-supporting-text-font: Open Sans;
    --mdc-snackbar-supporting-text-line-height: 1.25rem;
    --mdc-snackbar-supporting-text-size: 0.875rem;
    --mdc-snackbar-supporting-text-weight: 400;
  }
  html {
    --mat-table-header-headline-font: Quicksand;
    --mat-table-header-headline-line-height: 1.5rem;
    --mat-table-header-headline-size: 1rem;
    --mat-table-header-headline-weight: 400;
    --mat-table-header-headline-tracking: -0.4px;
    --mat-table-row-item-label-text-font: Open Sans;
    --mat-table-row-item-label-text-line-height: 1.25rem;
    --mat-table-row-item-label-text-size: 0.875rem;
    --mat-table-row-item-label-text-weight: 400;
    --mat-table-row-item-label-text-tracking: -0.2px;
    --mat-table-footer-supporting-text-font: Open Sans;
    --mat-table-footer-supporting-text-line-height: 1.25rem;
    --mat-table-footer-supporting-text-size: 0.875rem;
    --mat-table-footer-supporting-text-weight: 400;
    --mat-table-footer-supporting-text-tracking: -0.2px;
  }
}
.quantum-page-heading,
h1.quantum-page-heading {
  font-family: var(--quantum-sys-typography-heading-desktop-page-heading-font-family);
  font-weight: var(--quantum-sys-typography-heading-desktop-page-heading-font-weight);
  line-height: var(--quantum-sys-typography-heading-desktop-page-heading-line-height);
  font-size: var(--quantum-sys-typography-heading-desktop-page-heading-font-size);
  text-decoration: var(--quantum-sys-typography-heading-desktop-page-heading-text-decoration);
}
@media only screen and (max-width: 80em) {
  .quantum-page-heading,
  h1.quantum-page-heading {
    font-family: var(--quantum-sys-typography-heading-tablet-page-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-tablet-page-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-tablet-page-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-tablet-page-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-tablet-page-heading-text-decoration);
  }
}
@media only screen and (max-width: 37.5em) {
  .quantum-page-heading,
  h1.quantum-page-heading {
    font-family: var(--quantum-sys-typography-heading-mobile-page-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-mobile-page-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-mobile-page-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-mobile-page-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-mobile-page-heading-text-decoration);
  }
}

.quantum-page-subheading,
h3.quantum-page-subheading {
  font-family: var(--quantum-sys-typography-heading-desktop-page-sub-heading-font-family);
  font-weight: var(--quantum-sys-typography-heading-desktop-page-sub-heading-font-weight);
  line-height: var(--quantum-sys-typography-heading-desktop-page-sub-heading-line-height);
  font-size: var(--quantum-sys-typography-heading-desktop-page-sub-heading-font-size);
  text-decoration: var(--quantum-sys-typography-heading-desktop-sub-heading-text-decoration);
}
@media only screen and (max-width: 80em) {
  .quantum-page-subheading,
  h3.quantum-page-subheading {
    font-family: var(--quantum-sys-typography-heading-tablet-page-sub-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-tablet-page-sub-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-tablet-page-sub-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-tablet-page-sub-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-tablet-page-sub-heading-text-decoration);
  }
}
@media only screen and (max-width: 37.5em) {
  .quantum-page-subheading,
  h3.quantum-page-subheading {
    font-family: var(--quantum-sys-typography-heading-mobile-page-sub-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-mobile-page-sub-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-mobile-page-sub-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-mobile-page-sub-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-mobile-page-sub-heading-text-decoration);
  }
}

.quantum-section-heading,
h2.quantum-section-heading {
  font-family: var(--quantum-sys-typography-heading-desktop-section-heading-font-family);
  font-weight: var(--quantum-sys-typography-heading-desktop-section-heading-font-weight);
  line-height: var(--quantum-sys-typography-heading-desktop-section-heading-line-height);
  font-size: var(--quantum-sys-typography-heading-desktop-section-heading-font-size);
  text-decoration: var(--quantum-sys-typography-heading-desktop-section-heading-text-decoration);
}
@media only screen and (max-width: 80em) {
  .quantum-section-heading,
  h2.quantum-section-heading {
    font-family: var(--quantum-sys-typography-heading-tablet-section-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-tablet-section-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-tablet-section-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-tablet-section-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-tablet-section-heading-text-decoration);
  }
}
@media only screen and (max-width: 37.5em) {
  .quantum-section-heading,
  h2.quantum-section-heading {
    font-family: var(--quantum-sys-typography-heading-mobile-section-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-mobile-section-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-mobile-section-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-mobile-section-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-mobile-section-heading-text-decoration);
  }
}

.quantum-section-subheading,
h4.quantum-section-subheading {
  font-family: var(--quantum-sys-typography-heading-desktop-section-sub-heading-font-family);
  font-weight: var(--quantum-sys-typography-heading-desktop-section-sub-heading-font-weight);
  line-height: var(--quantum-sys-typography-heading-desktop-section-sub-heading-line-height);
  font-size: var(--quantum-sys-typography-heading-desktop-section-sub-heading-font-size);
  text-decoration: var(--quantum-sys-typography-heading-desktop-section-sub-heading-text-decoration);
}
@media only screen and (max-width: 80em) {
  .quantum-section-subheading,
  h4.quantum-section-subheading {
    font-family: var(--quantum-sys-typography-heading-tablet-section-sub-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-tablet-section-sub-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-tablet-section-sub-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-tablet-section-sub-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-tablet-section-sub-heading-text-decoration);
  }
}
@media only screen and (max-width: 37.5em) {
  .quantum-section-subheading,
  h4.quantum-section-subheading {
    font-family: var(--quantum-sys-typography-heading-mobile-section-subheading-font-family);
    font-weight: var(--quantum-sys-typography-heading-mobile-section-subheading-font-weight);
    line-height: var(--quantum-sys-typography-heading-mobile-section-subheading-line-height);
    font-size: var(--quantum-sys-typography-heading-mobile-section-subheading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-mobile-section-subheading-text-decoration);
  }
}

.quantum-card-heading,
h2.quantum-card-heading {
  font-family: var(--quantum-sys-typography-heading-desktop-card-heading-font-family);
  font-weight: var(--quantum-sys-typography-heading-desktop-card-heading-font-weight);
  line-height: var(--quantum-sys-typography-heading-desktop-card-heading-line-height);
  font-size: var(--quantum-sys-typography-heading-desktop-card-heading-font-size);
  text-decoration: var(--quantum-sys-typography-heading-desktop-card-heading-text-decoration);
}
@media only screen and (max-width: 80em) {
  .quantum-card-heading,
  h2.quantum-card-heading {
    font-family: var(--quantum-sys-typography-heading-tablet-card-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-tablet-card-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-tablet-card-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-tablet-card-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-tablet-card-heading-text-decoration);
  }
}
@media only screen and (max-width: 37.5em) {
  .quantum-card-heading,
  h2.quantum-card-heading {
    font-family: var(--quantum-sys-typography-heading-mobile-card-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-mobile-card-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-mobile-card-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-mobile-card-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-mobile-card-heading-text-decoration);
  }
}

.quantum-card-subheading,
h3.quantum-card-subheading {
  font-family: var(--quantum-sys-typography-heading-desktop-card-sub-heading-font-family);
  font-weight: var(--quantum-sys-typography-heading-desktop-card-sub-heading-font-weight);
  line-height: var(--quantum-sys-typography-heading-desktop-card-sub-heading-line-height);
  font-size: var(--quantum-sys-typography-heading-desktop-card-sub-heading-font-size);
  text-decoration: var(--quantum-sys-typography-heading-desktop-card-sub-heading-text-decoration);
}
@media only screen and (max-width: 80em) {
  .quantum-card-subheading,
  h3.quantum-card-subheading {
    font-family: var(--quantum-sys-typography-heading-tablet-card-sub-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-tablet-card-sub-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-tablet-card-sub-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-tablet-card-sub-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-tablet-card-sub-heading-text-decoration);
  }
}
@media only screen and (max-width: 37.5em) {
  .quantum-card-subheading,
  h3.quantum-card-subheading {
    font-family: var(--quantum-sys-typography-heading-mobile-card-subheading-font-family);
    font-weight: var(--quantum-sys-typography-heading-mobile-card-subheading-font-weight);
    line-height: var(--quantum-sys-typography-heading-mobile-card-subheading-line-height);
    font-size: var(--quantum-sys-typography-heading-mobile-card-subheading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-mobile-card-subheading-text-decoration);
  }
}

.quantum-body-large-regular {
  font-family: var(--quantum-sys-typography-body-large-regular-font-family);
  font-weight: var(--quantum-sys-typography-body-large-regular-font-weight);
  line-height: var(--quantum-sys-typography-body-large-regular-line-height);
  font-size: var(--quantum-sys-typography-body-large-regular-font-size);
  text-decoration: var(--quantum-sys-typography-body-large-regular-text-decoration);
}

.quantum-body-large-medium {
  font-family: var(--quantum-sys-typography-body-large-medium-font-family);
  font-weight: var(--quantum-sys-typography-body-large-medium-font-weight);
  line-height: var(--quantum-sys-typography-body-large-medium-line-height);
  font-size: var(--quantum-sys-typography-body-large-medium-font-size);
  text-decoration: var(--quantum-sys-typography-body-large-medium-text-decoration);
}

.quantum-body-large-bold {
  font-family: var(--quantum-sys-typography-body-large-bold-font-family);
  font-weight: var(--quantum-sys-typography-body-large-bold-font-weight);
  line-height: var(--quantum-sys-typography-body-large-bold-line-height);
  font-size: var(--quantum-sys-typography-body-large-bold-font-size);
  text-decoration: var(--quantum-sys-typography-body-large-bold-text-decoration);
}

.quantum-body-large-link {
  font-family: var(--quantum-sys-typography-body-large-link-font-family);
  font-weight: var(--quantum-sys-typography-body-large-link-font-weight);
  line-height: var(--quantum-sys-typography-body-large-link-line-height);
  font-size: var(--quantum-sys-typography-body-large-link-font-size);
  text-decoration: var(--quantum-sys-typography-body-large-link-text-decoration);
}

.quantum-body-medium-regular {
  font-family: var(--quantum-sys-typography-body-medium-regular-font-family);
  font-weight: var(--quantum-sys-typography-body-medium-regular-font-weight);
  line-height: var(--quantum-sys-typography-body-medium-regular-line-height);
  font-size: var(--quantum-sys-typography-body-medium-regular-font-size);
  text-decoration: var(--quantum-sys-typography-body-medium-regular-text-decoration);
}

.quantum-body-medium-medium {
  font-family: var(--quantum-sys-typography-body-medium-medium-font-family);
  font-weight: var(--quantum-sys-typography-body-medium-medium-font-weight);
  line-height: var(--quantum-sys-typography-body-medium-medium-line-height);
  font-size: var(--quantum-sys-typography-body-medium-medium-font-size);
  text-decoration: var(--quantum-sys-typography-body-medium-medium-text-decoration);
}

.quantum-body-medium-bold {
  font-family: var(--quantum-sys-typography-body-medium-bold-font-family);
  font-weight: var(--quantum-sys-typography-body-medium-bold-font-weight);
  line-height: var(--quantum-sys-typography-body-medium-bold-line-height);
  font-size: var(--quantum-sys-typography-body-medium-bold-font-size);
  text-decoration: var(--quantum-sys-typography-body-medium-bold-text-decoration);
}

.quantum-body-medium-link {
  font-family: var(--quantum-sys-typography-body-medium-link-font-family);
  font-weight: var(--quantum-sys-typography-body-medium-link-font-weight);
  line-height: var(--quantum-sys-typography-body-medium-link-line-height);
  font-size: var(--quantum-sys-typography-body-medium-link-font-size);
  text-decoration: var(--quantum-sys-typography-body-medium-link-text-decoration);
}

.quantum-body-small-regular {
  font-family: var(--quantum-sys-typography-body-small-regular-font-family);
  font-weight: var(--quantum-sys-typography-body-small-regular-font-weight);
  line-height: var(--quantum-sys-typography-body-small-regular-line-height);
  font-size: var(--quantum-sys-typography-body-small-regular-font-size);
  text-decoration: var(--quantum-sys-typography-body-small-regular-text-decoration);
}

.quantum-body-small-medium {
  font-family: var(--quantum-sys-typography-body-small-medium-font-family);
  font-weight: var(--quantum-sys-typography-body-small-medium-font-weight);
  line-height: var(--quantum-sys-typography-body-small-medium-line-height);
  font-size: var(--quantum-sys-typography-body-small-medium-font-size);
  text-decoration: var(--quantum-sys-typography-body-small-medium-text-decoration);
}

.quantum-body-small-bold {
  font-family: var(--quantum-sys-typography-body-small-bold-font-family);
  font-weight: var(--quantum-sys-typography-body-small-bold-font-weight);
  line-height: var(--quantum-sys-typography-body-small-bold-line-height);
  font-size: var(--quantum-sys-typography-body-small-bold-font-size);
  text-decoration: var(--quantum-sys-typography-body-small-bold-text-decoration);
}

.quantum-body-small-link {
  font-family: var(--quantum-sys-typography-body-small-link-font-family);
  font-weight: var(--quantum-sys-typography-body-small-link-font-weight);
  line-height: var(--quantum-sys-typography-body-small-link-line-height);
  font-size: var(--quantum-sys-typography-body-small-link-font-size);
  text-decoration: var(--quantum-sys-typography-body-small-link-text-decoration);
}

.quantum-button {
  font-size: var(--quantum-sys-typography-button-font-size);
  line-height: var(--quantum-sys-typography-button-line-height);
  font-weight: var(--quantum-sys-typography-button-font-weight);
  font-family: var(--quantum-sys-typography-button-font-family);
  text-decoration: var(--quantum-sys-typography-button-text-decoration);
  letter-spacing: -0.2px;
}

.quantum-caption {
  font-family: var(--quantum-sys-typography-caption-font-family);
  font-weight: var(--quantum-sys-typography-caption-font-weight);
  line-height: var(--quantum-sys-typography-caption-line-height);
  font-size: var(--quantum-sys-typography-caption-font-size);
  text-decoration: var(--quantum-sys-typography-caption-text-decoration);
}

.quantum-badge {
  font-family: var(--quantum-sys-typography-badge-font-family);
  font-weight: var(--quantum-sys-typography-badge-font-weight);
  line-height: var(--quantum-sys-typography-badge-line-height);
  font-size: var(--quantum-sys-typography-badge-font-size);
  text-decoration: var(--quantum-sys-typography-badge-text-decoration);
}

html,
body {
  background: var(--Background);
  font-family: var(--body-font-family);
  height: 100%;
  margin: 0;
  overflow-y: auto;
  padding: 0;
}

.page__container--fixed-width {
  width: 100%;
  max-width: 80rem;
  margin-inline: auto;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #15828e;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #d3f4f8;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #ba1a1a;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #15828e;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #15828e;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #d3f4f8;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #d3f4f8;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #d3f4f8;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #d3f4f8;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ba1a1a;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ba1a1a;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #15828e;
  --mdc-linear-progress-track-color: rgba(21, 130, 142, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(21, 130, 142, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(21, 130, 142, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(21, 130, 142, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(21, 130, 142, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(21, 130, 142, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #d3f4f8;
  --mdc-linear-progress-track-color: rgba(211, 244, 248, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(211, 244, 248, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(211, 244, 248, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(211, 244, 248, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(211, 244, 248, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(211, 244, 248, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ba1a1a;
  --mdc-linear-progress-track-color: rgba(186, 26, 26, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(186, 26, 26, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(186, 26, 26, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(186, 26, 26, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(186, 26, 26, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(186, 26, 26, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-caret-color: #15828e;
  --mdc-filled-text-field-focus-active-indicator-color: #15828e;
  --mdc-filled-text-field-focus-label-text-color: rgba(21, 130, 142, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ba1a1a;
  --mdc-filled-text-field-error-label-text-color: #ba1a1a;
  --mdc-filled-text-field-error-caret-color: #ba1a1a;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ba1a1a;
  --mdc-outlined-text-field-caret-color: #15828e;
  --mdc-outlined-text-field-focus-outline-color: #15828e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(21, 130, 142, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ba1a1a;
  --mdc-outlined-text-field-error-focus-label-text-color: #ba1a1a;
  --mdc-outlined-text-field-error-label-text-color: #ba1a1a;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ba1a1a;
  --mdc-outlined-text-field-error-hover-outline-color: #ba1a1a;
  --mdc-outlined-text-field-error-outline-color: #ba1a1a;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ba1a1a);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(21, 130, 142, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(211, 244, 248, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(186, 26, 26, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #d3f4f8;
  --mdc-filled-text-field-focus-active-indicator-color: #d3f4f8;
  --mdc-filled-text-field-focus-label-text-color: rgba(211, 244, 248, 0.87);
  --mdc-outlined-text-field-caret-color: #d3f4f8;
  --mdc-outlined-text-field-focus-outline-color: #d3f4f8;
  --mdc-outlined-text-field-focus-label-text-color: rgba(211, 244, 248, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ba1a1a;
  --mdc-filled-text-field-focus-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-focus-label-text-color: rgba(186, 26, 26, 0.87);
  --mdc-outlined-text-field-caret-color: #ba1a1a;
  --mdc-outlined-text-field-focus-outline-color: #ba1a1a;
  --mdc-outlined-text-field-focus-label-text-color: rgba(186, 26, 26, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(21, 130, 142, 0.87);
  --mat-select-invalid-arrow-color: rgba(186, 26, 26, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(211, 244, 248, 0.87);
  --mat-select-invalid-arrow-color: rgba(186, 26, 26, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(186, 26, 26, 0.87);
  --mat-select-invalid-arrow-color: rgba(186, 26, 26, 0.87);
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #1a1a1a;
  --mdc-chip-elevated-container-color: #15828e;
  --mdc-chip-elevated-disabled-container-color: #15828e;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #1a1a1a;
  --mdc-chip-with-icon-icon-color: #1a1a1a;
  --mdc-chip-with-icon-disabled-icon-color: #1a1a1a;
  --mdc-chip-with-icon-selected-icon-color: #1a1a1a;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #1a1a1a;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #1a1a1a;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: #1a1a1a;
  --mdc-chip-elevated-container-color: #d3f4f8;
  --mdc-chip-elevated-disabled-container-color: #d3f4f8;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #1a1a1a;
  --mdc-chip-with-icon-icon-color: #1a1a1a;
  --mdc-chip-with-icon-disabled-icon-color: #1a1a1a;
  --mdc-chip-with-icon-selected-icon-color: #1a1a1a;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #1a1a1a;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #1a1a1a;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #1a1a1a;
  --mdc-chip-elevated-container-color: #ba1a1a;
  --mdc-chip-elevated-disabled-container-color: #ba1a1a;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #1a1a1a;
  --mdc-chip-with-icon-icon-color: #1a1a1a;
  --mdc-chip-with-icon-disabled-icon-color: #1a1a1a;
  --mdc-chip-with-icon-selected-icon-color: #1a1a1a;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #1a1a1a;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #1a1a1a;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #333333;
  --mdc-switch-selected-handle-color: #333333;
  --mdc-switch-selected-hover-state-layer-color: #333333;
  --mdc-switch-selected-pressed-state-layer-color: #333333;
  --mdc-switch-selected-focus-handle-color: #1a1a1a;
  --mdc-switch-selected-hover-handle-color: #1a1a1a;
  --mdc-switch-selected-pressed-handle-color: #1a1a1a;
  --mdc-switch-selected-focus-track-color: #737373;
  --mdc-switch-selected-hover-track-color: #737373;
  --mdc-switch-selected-pressed-track-color: #737373;
  --mdc-switch-selected-track-color: #737373;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #333333;
  --mdc-switch-selected-handle-color: #333333;
  --mdc-switch-selected-hover-state-layer-color: #333333;
  --mdc-switch-selected-pressed-state-layer-color: #333333;
  --mdc-switch-selected-focus-handle-color: #1a1a1a;
  --mdc-switch-selected-hover-handle-color: #1a1a1a;
  --mdc-switch-selected-pressed-handle-color: #1a1a1a;
  --mdc-switch-selected-focus-track-color: #737373;
  --mdc-switch-selected-hover-track-color: #737373;
  --mdc-switch-selected-pressed-track-color: #737373;
  --mdc-switch-selected-track-color: #737373;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #333333;
  --mdc-switch-selected-handle-color: #333333;
  --mdc-switch-selected-hover-state-layer-color: #333333;
  --mdc-switch-selected-pressed-state-layer-color: #333333;
  --mdc-switch-selected-focus-handle-color: #1a1a1a;
  --mdc-switch-selected-hover-handle-color: #1a1a1a;
  --mdc-switch-selected-pressed-handle-color: #1a1a1a;
  --mdc-switch-selected-focus-track-color: #737373;
  --mdc-switch-selected-hover-track-color: #737373;
  --mdc-switch-selected-pressed-track-color: #737373;
  --mdc-switch-selected-track-color: #737373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #15828e;
  --mdc-radio-selected-hover-icon-color: #15828e;
  --mdc-radio-selected-icon-color: #15828e;
  --mdc-radio-selected-pressed-icon-color: #15828e;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #15828e;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #d3f4f8;
  --mdc-radio-selected-hover-icon-color: #d3f4f8;
  --mdc-radio-selected-icon-color: #d3f4f8;
  --mdc-radio-selected-pressed-icon-color: #d3f4f8;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #d3f4f8;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ba1a1a;
  --mdc-radio-selected-hover-icon-color: #ba1a1a;
  --mdc-radio-selected-icon-color: #ba1a1a;
  --mdc-radio-selected-pressed-icon-color: #ba1a1a;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ba1a1a;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #15828e;
  --mdc-slider-focus-handle-color: #15828e;
  --mdc-slider-hover-handle-color: #15828e;
  --mdc-slider-active-track-color: #15828e;
  --mdc-slider-inactive-track-color: #15828e;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #15828e;
  --mat-mdc-slider-ripple-color: #15828e;
  --mat-mdc-slider-hover-ripple-color: rgba(21, 130, 142, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(21, 130, 142, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #d3f4f8;
  --mdc-slider-focus-handle-color: #d3f4f8;
  --mdc-slider-hover-handle-color: #d3f4f8;
  --mdc-slider-active-track-color: #d3f4f8;
  --mdc-slider-inactive-track-color: #d3f4f8;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #d3f4f8;
  --mat-mdc-slider-ripple-color: #d3f4f8;
  --mat-mdc-slider-hover-ripple-color: rgba(211, 244, 248, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(211, 244, 248, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ba1a1a;
  --mdc-slider-focus-handle-color: #ba1a1a;
  --mdc-slider-hover-handle-color: #ba1a1a;
  --mdc-slider-active-track-color: #ba1a1a;
  --mdc-slider-inactive-track-color: #ba1a1a;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ba1a1a;
  --mat-mdc-slider-ripple-color: #ba1a1a;
  --mat-mdc-slider-hover-ripple-color: rgba(186, 26, 26, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(186, 26, 26, 0.2);
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #15828e;
  --mdc-radio-selected-hover-icon-color: #15828e;
  --mdc-radio-selected-icon-color: #15828e;
  --mdc-radio-selected-pressed-icon-color: #15828e;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #d3f4f8;
  --mdc-radio-selected-hover-icon-color: #d3f4f8;
  --mdc-radio-selected-icon-color: #d3f4f8;
  --mdc-radio-selected-pressed-icon-color: #d3f4f8;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ba1a1a;
  --mdc-radio-selected-hover-icon-color: #ba1a1a;
  --mdc-radio-selected-icon-color: #ba1a1a;
  --mdc-radio-selected-pressed-icon-color: #ba1a1a;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #15828e;
  --mdc-checkbox-selected-hover-icon-color: #15828e;
  --mdc-checkbox-selected-icon-color: #15828e;
  --mdc-checkbox-selected-pressed-icon-color: #15828e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #15828e;
  --mdc-checkbox-selected-hover-state-layer-color: #15828e;
  --mdc-checkbox-selected-pressed-state-layer-color: #15828e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #d3f4f8;
  --mdc-checkbox-selected-hover-icon-color: #d3f4f8;
  --mdc-checkbox-selected-icon-color: #d3f4f8;
  --mdc-checkbox-selected-pressed-icon-color: #d3f4f8;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d3f4f8;
  --mdc-checkbox-selected-hover-state-layer-color: #d3f4f8;
  --mdc-checkbox-selected-pressed-state-layer-color: #d3f4f8;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ba1a1a;
  --mdc-checkbox-selected-hover-icon-color: #ba1a1a;
  --mdc-checkbox-selected-icon-color: #ba1a1a;
  --mdc-checkbox-selected-pressed-icon-color: #ba1a1a;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ba1a1a;
  --mdc-checkbox-selected-hover-state-layer-color: #ba1a1a;
  --mdc-checkbox-selected-pressed-state-layer-color: #ba1a1a;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #15828e;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #15828e;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #15828e;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #15828e;
  --mat-tab-header-active-ripple-color: #15828e;
  --mat-tab-header-inactive-ripple-color: #15828e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #15828e;
  --mat-tab-header-active-hover-label-text-color: #15828e;
  --mat-tab-header-active-focus-indicator-color: #15828e;
  --mat-tab-header-active-hover-indicator-color: #15828e;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #d3f4f8;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #d3f4f8;
  --mat-tab-header-active-ripple-color: #d3f4f8;
  --mat-tab-header-inactive-ripple-color: #d3f4f8;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #d3f4f8;
  --mat-tab-header-active-hover-label-text-color: #d3f4f8;
  --mat-tab-header-active-focus-indicator-color: #d3f4f8;
  --mat-tab-header-active-hover-indicator-color: #d3f4f8;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ba1a1a;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ba1a1a;
  --mat-tab-header-active-ripple-color: #ba1a1a;
  --mat-tab-header-inactive-ripple-color: #ba1a1a;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ba1a1a;
  --mat-tab-header-active-hover-label-text-color: #ba1a1a;
  --mat-tab-header-active-focus-indicator-color: #ba1a1a;
  --mat-tab-header-active-hover-indicator-color: #ba1a1a;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #15828e;
  --mat-tab-header-with-background-foreground-color: #1a1a1a;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #d3f4f8;
  --mat-tab-header-with-background-foreground-color: #1a1a1a;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ba1a1a;
  --mat-tab-header-with-background-foreground-color: #1a1a1a;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #d3f4f8;
  --mdc-checkbox-selected-hover-icon-color: #d3f4f8;
  --mdc-checkbox-selected-icon-color: #d3f4f8;
  --mdc-checkbox-selected-pressed-icon-color: #d3f4f8;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d3f4f8;
  --mdc-checkbox-selected-hover-state-layer-color: #d3f4f8;
  --mdc-checkbox-selected-pressed-state-layer-color: #d3f4f8;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #15828e;
  --mdc-checkbox-selected-hover-icon-color: #15828e;
  --mdc-checkbox-selected-icon-color: #15828e;
  --mdc-checkbox-selected-pressed-icon-color: #15828e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #15828e;
  --mdc-checkbox-selected-hover-state-layer-color: #15828e;
  --mdc-checkbox-selected-pressed-state-layer-color: #15828e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ba1a1a;
  --mdc-checkbox-selected-hover-icon-color: #ba1a1a;
  --mdc-checkbox-selected-icon-color: #ba1a1a;
  --mdc-checkbox-selected-pressed-icon-color: #ba1a1a;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ba1a1a;
  --mdc-checkbox-selected-hover-state-layer-color: #ba1a1a;
  --mdc-checkbox-selected-pressed-state-layer-color: #ba1a1a;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #15828e;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #d3f4f8;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ba1a1a;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #15828e;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #d3f4f8;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ba1a1a;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #15828e;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #d3f4f8;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ba1a1a;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #15828e;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #d3f4f8;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ba1a1a;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #15828e;
  --mat-mdc-button-ripple-color: rgba(21, 130, 142, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #d3f4f8;
  --mat-mdc-button-ripple-color: rgba(211, 244, 248, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ba1a1a;
  --mat-mdc-button-ripple-color: rgba(186, 26, 26, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #15828e;
  --mat-mdc-button-persistent-ripple-color: #15828e;
  --mat-mdc-button-ripple-color: rgba(21, 130, 142, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #d3f4f8;
  --mat-mdc-button-persistent-ripple-color: #d3f4f8;
  --mat-mdc-button-ripple-color: rgba(211, 244, 248, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ba1a1a;
  --mat-mdc-button-persistent-ripple-color: #ba1a1a;
  --mat-mdc-button-ripple-color: rgba(186, 26, 26, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #15828e;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #d3f4f8;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ba1a1a;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #d3f4f8;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #15828e;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #d3f4f8;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ba1a1a;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #15828e;
  --mat-badge-text-color: #1a1a1a;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #d3f4f8;
  --mat-badge-text-color: #1a1a1a;
}

.mat-badge-warn {
  --mat-badge-background-color: #ba1a1a;
  --mat-badge-text-color: #1a1a1a;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: #1a1a1a;
  --mat-datepicker-calendar-date-selected-state-background-color: #15828e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(21, 130, 142, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #1a1a1a;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(21, 130, 142, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(21, 130, 142, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #15828e;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(21, 130, 142, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: #1a1a1a;
  --mat-datepicker-calendar-date-selected-state-background-color: #d3f4f8;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(211, 244, 248, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #1a1a1a;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(211, 244, 248, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(211, 244, 248, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(211, 244, 248, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #1a1a1a;
  --mat-datepicker-calendar-date-selected-state-background-color: #ba1a1a;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(186, 26, 26, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #1a1a1a;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(186, 26, 26, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(186, 26, 26, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(186, 26, 26, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #d3f4f8;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ba1a1a;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #15828e;
}
.mat-icon.mat-accent {
  --mat-icon-color: #d3f4f8;
}
.mat-icon.mat-warn {
  --mat-icon-color: #ba1a1a;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: #1a1a1a;
  --mat-stepper-header-selected-state-icon-background-color: #15828e;
  --mat-stepper-header-selected-state-icon-foreground-color: #1a1a1a;
  --mat-stepper-header-done-state-icon-background-color: #15828e;
  --mat-stepper-header-done-state-icon-foreground-color: #1a1a1a;
  --mat-stepper-header-edit-state-icon-background-color: #15828e;
  --mat-stepper-header-edit-state-icon-foreground-color: #1a1a1a;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #ba1a1a;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #ba1a1a;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: #1a1a1a;
  --mat-stepper-header-selected-state-icon-background-color: #d3f4f8;
  --mat-stepper-header-selected-state-icon-foreground-color: #1a1a1a;
  --mat-stepper-header-done-state-icon-background-color: #d3f4f8;
  --mat-stepper-header-done-state-icon-foreground-color: #1a1a1a;
  --mat-stepper-header-edit-state-icon-background-color: #d3f4f8;
  --mat-stepper-header-edit-state-icon-foreground-color: #1a1a1a;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #1a1a1a;
  --mat-stepper-header-selected-state-icon-background-color: #ba1a1a;
  --mat-stepper-header-selected-state-icon-foreground-color: #1a1a1a;
  --mat-stepper-header-done-state-icon-background-color: #ba1a1a;
  --mat-stepper-header-done-state-icon-foreground-color: #1a1a1a;
  --mat-stepper-header-edit-state-icon-background-color: #ba1a1a;
  --mat-stepper-header-edit-state-icon-foreground-color: #1a1a1a;
}

html {
  --mat-stepper-header-height: 72px;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #15828e;
  --mat-toolbar-container-text-color: #1a1a1a;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #d3f4f8;
  --mat-toolbar-container-text-color: #1a1a1a;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ba1a1a;
  --mat-toolbar-container-text-color: #1a1a1a;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #15828e;
  --mdc-checkbox-selected-hover-icon-color: #15828e;
  --mdc-checkbox-selected-icon-color: #15828e;
  --mdc-checkbox-selected-pressed-icon-color: #15828e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #15828e;
  --mdc-checkbox-selected-hover-state-layer-color: #15828e;
  --mdc-checkbox-selected-pressed-state-layer-color: #15828e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #15828e;
  --mdc-checkbox-selected-hover-icon-color: #15828e;
  --mdc-checkbox-selected-icon-color: #15828e;
  --mdc-checkbox-selected-pressed-icon-color: #15828e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #15828e;
  --mdc-checkbox-selected-hover-state-layer-color: #15828e;
  --mdc-checkbox-selected-pressed-state-layer-color: #15828e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ba1a1a;
  --mdc-checkbox-selected-hover-icon-color: #ba1a1a;
  --mdc-checkbox-selected-icon-color: #ba1a1a;
  --mdc-checkbox-selected-pressed-icon-color: #ba1a1a;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ba1a1a;
  --mdc-checkbox-selected-hover-state-layer-color: #ba1a1a;
  --mdc-checkbox-selected-pressed-state-layer-color: #ba1a1a;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-infix {
  min-height: 40px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.dark-theme .mat-mdc-tab-group, .dark-theme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #21cbde;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #21cbde;
  --mat-tab-header-active-ripple-color: #21cbde;
  --mat-tab-header-inactive-ripple-color: #21cbde;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #21cbde;
  --mat-tab-header-active-hover-label-text-color: #21cbde;
  --mat-tab-header-active-focus-indicator-color: #21cbde;
  --mat-tab-header-active-hover-indicator-color: #21cbde;
}
.dark-theme .mat-mdc-tab-group.mat-accent, .dark-theme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #0d5059;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #0d5059;
  --mat-tab-header-active-ripple-color: #0d5059;
  --mat-tab-header-inactive-ripple-color: #0d5059;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0d5059;
  --mat-tab-header-active-hover-label-text-color: #0d5059;
  --mat-tab-header-active-focus-indicator-color: #0d5059;
  --mat-tab-header-active-hover-indicator-color: #0d5059;
}
.dark-theme .mat-mdc-tab-group.mat-warn, .dark-theme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ec7979;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #ec7979;
  --mat-tab-header-active-ripple-color: #ec7979;
  --mat-tab-header-inactive-ripple-color: #ec7979;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ec7979;
  --mat-tab-header-active-hover-label-text-color: #ec7979;
  --mat-tab-header-active-focus-indicator-color: #ec7979;
  --mat-tab-header-active-hover-indicator-color: #ec7979;
}
.dark-theme .mat-mdc-tab-group.mat-background-primary, .dark-theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #21cbde;
  --mat-tab-header-with-background-foreground-color: #e6e6e6;
}
.dark-theme .mat-mdc-tab-group.mat-background-accent, .dark-theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #0d5059;
  --mat-tab-header-with-background-foreground-color: #e6e6e6;
}
.dark-theme .mat-mdc-tab-group.mat-background-warn, .dark-theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ec7979;
  --mat-tab-header-with-background-foreground-color: #e6e6e6;
}

/* Colour definitions
   This file is now deprecated. 
   Please use the CSS variables from all-themes.css from the @quantum/tokens
   package for all component colors.
*/
:root {
  --Midnight-Ocean: hsla(218, 29%, 22%, 1);
  --Blood-Orange: hwb(15 20% 0%);
  --Arctic-Blue: hsla(187, 75%, 95%, 1);
  --Sky-Blue: hsla(196, 100%, 47%, 1);
  --Sky-Blue-24: hsla(196, 100%, 24%, 1);
  --Blue-60: hsla(216, 82%, 57%, 1);
  --Navy-Blue: #000d69;
  --V-Dark-Grey: var(--Neutral-700);
  --V-Grey-Dark: var(--Neutral-500);
  --V-Grey: var(--quantum-on-surface-low-emphasis);
  --V-White: var(--Neutral-50);
  --Neutral-900: var(--quantum-on-surface-high-emphasis);
  --Neutral-700: var(--quantum-nav-surface);
  --Neutral-600: var(--quantum-nav-surface);
  --Neutral-500: var(--quantum-on-surface-medium-emphasis);
  --Neutral-300: var(--quantum-on-surface-low-emphasis);
  --Neutral-200: var(--quantum-neutral-press);
  --Neutral-100: var(--quantum-neutral-default);
  --Neutral-50: var(--quantum-surface-primary);
  --Opacity-Default: 100%;
  --Opacity-Disabled: 38%;
  --Primary-Shadow: var(--quantum-primary-shadow);
  --Primary-Pressed: var(--quantum-primary-press);
  --Primary-Hover: var(--quantum-primary-hover);
  --Primary: var(--quantum-primary-default);
  --Primary-Disabled: var(--quantum-primary-toggle);
  --On-Primary: var(--quantum-primary-on);
  --Tonal-Shadow: var(--quantum-tonal-shadow);
  --Tonal-Pressed: var(--quantum-tonal-press);
  --Tonal-Hover: var(--quantum-tonal-hover);
  --Tonal: var(--quantum-tonal-default);
  --Tonal-Disabled: rgba(var(--quantum-tonal-default), 0.38);
  --On-Tonal: var(--quantum-tonal-on);
  --Outlined-Shadow: var(--quantum-outlined-shadow);
  --Outlined-Pressed: var(--quantum-outlined-press);
  --Outlined-Hover: var(--quantum-outlined-hover);
  --Outlined: var(--quantum-outlined-on);
  --Danger-Shadow: var(--quantum-danger-shadow);
  --Danger-Pressed: var(--quantum-danger-press);
  --Danger-Hover: var(--quantum-danger-hover);
  --Danger: var(--quantum-danger-default);
  --On-Danger: var(--quantum-danger-on);
  --Danger-Surface: var(--quantum-surface-danger);
  --Navbar: var(--quantum-nav-surface);
  --On-Navbar: var(--quantum-nav-button-on);
  --Accent: var(--Tonal);
  --On-Accent: var(--On-Tonal);
  --Accent-Hover: var(--Tonal-Hover);
  --Background: var(--quantum-background);
  --On-Background: var(--quantum-on-surface-high-emphasis);
  --Surface: var(--quantum-surface-primary);
  --On-Surface: var(--quantum-on-surface-high-emphasis);
  --Collection-1: var(--Navy-Blue);
  --Collection-2: hsla(0, 0%, 49%, 100%);
  --Collection-3: hsla(196, 100%, 49%, 100%);
  --Collection-4: hsla(230, 100%, 49%, 100%);
  --Collection-5: hsla(162, 39%, 49%, 100%);
  --Status-Success: var(--quantum-success-default);
  --On-Status-Success: var(--quantum-success-on);
  --Status-Success-Hover: var(--quantum-success-hover);
  --Status-Warning: var(--quantum-warning-default);
  --On-Status-Warning: var(--quantum-warning-on);
  --Status-Warning-Hover: var(--quantum-warning-hover);
  --Status-Error: var(--quantum-danger-default);
  --On-Status-Error: var(--quantum-danger-on);
  --Status-Error-Hover: var(--quantum-danger-hover);
  --Low-Emphasis: var(--quantum-on-surface-low-emphasis);
  --Medium-Emphasis: var(--quantum-on-surface-medium-emphasis);
  --High-Emphasis: var(--quantum-on-surface-high-emphasis);
  --Disabled: var(--Neutral-300);
}

/*
  0 - 600px: Extra small
  600-959px: Small
  960-1279px: Medium
  1280+: Large,  is where our normal styles apply

  $breakpoint argument choices:visited
  - xs
  - s
  - m
  - l

  1em=16px
  */
a {
  color: var(--Primary);
}

.mat-ripple {
  display: none;
}

.mat-tree-node {
  font-size: 1rem;
  height: 2.25rem;
  min-height: 1.8em !important;
}
.mat-tree-node button.mat-mdc-icon-button {
  height: 3.25rem;
  min-height: 1.8em;
  line-height: 1.6em;
}

.hide {
  visibility: hidden;
}

img:not([src]) {
  visibility: hidden;
}

sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}

.tooltip-list {
  white-space: pre;
}

.tooltip-list > * {
  text-align: left !important;
}

.vendor-agent__dialog-container .mat-mdc-dialog-container {
  padding: 0px;
}

app-help-centre {
  position: absolute;
  inset: 0;
}

.property_takeover__address {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block-end: 1.5rem;
  color: var(--Primary);
  font-size: 1.1rem;
}

.mat-form-field-flex {
  align-items: center;
}

.mat-form-field-outline {
  background-color: var(--Surface);
  color: var(--Outlined);
  border-radius: 0.5rem;
}

.mat-form-field.mat-focused .mat-form-field-label mat-label {
  background-color: var(--Surface);
  padding-block: 0.25rem;
  border-radius: 0.125rem;
}

.mat-step-icon-content {
  color: var(--Surface);
}

.mat-expansion-panel-header-title h1,
.mat-expansion-panel-header-title cdk-global-overlay-wrapper h2,
.mat-expansion-panel-header-title h3 {
  margin-block-end: 0.5rem;
}

.mat-expansion-panel-body {
  padding: 0.5rem 1rem !important;
}

.leaflet-container a.leaflet-popup-close-button {
  cursor: pointer;
}

mat-stepper .mat-vertical-content-container {
  margin-inline-start: 12px;
}
mat-stepper .mat-vertical-stepper-header {
  padding-block: 24px;
  padding-inline: 0;
}

@media (max-width: 37.5em) {
  #map .leaflet-control-zoom {
    margin-block-end: 3rem;
  }
}

.search-autocomplete {
  max-height: 60vh !important;
}

.leaflet-popup.draw-popup {
  font-family: var(--OpenSans-font-stack);
  text-shadow: 1px 0 var(--Neutral-50), -1px 0 var(--Neutral-50), 0 1px var(--Neutral-50), 0 -1px var(--Neutral-50), 1px 1px var(--Neutral-50), -1px -1px var(--Neutral-50), 1px -1px var(--Neutral-50), -1px 1px var(--Neutral-50);
}

.sectionsMobileMenu {
  width: 100vw;
  box-shadow: 0 4px 7px -5px var(--V-Grey-Dark);
  border-radius: 0 !important;
  max-width: initial !important;
}

#topMenu {
  display: none;
}
@media (max-width: 768px) {
  #topMenu.isVisible {
    display: flex;
  }
}

.sectionMobileMenuBody {
  scroll-padding-top: 60px;
}

.leaflet-measure-path-measurement {
  position: absolute;
  font-size: 0.875rem;
  font-family: var(--Quicksand-font-stack);
  font-weight: 800;
  color: black;
  text-shadow: -1px 0 0 white, -1px -1px 0 white, 0 -1px 0 white, 1px -1px 0 white, 1px 0 0 white, 1px 1px 0 white, 0 1px 0 white, -1px 1px 0 white;
  white-space: nowrap;
  transform-origin: 0;
  pointer-events: none;
}

.leaflet-measure-path-measurement > div {
  position: relative;
  margin-top: -25%;
  left: -50%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--Surface) inset;
}

.mat-mdc-menu-item.mdc-list-item {
  --mdc-theme-text-primary-on-background: var(--Primary);
  font-size: 0.875rem;
}
.mat-mdc-menu-item.mdc-list-item span {
  color: var(--Neutral-900);
  font-size: 0.875rem;
  font-weight: 400;
}

.mat-mdc-menu-item {
  --mat-menu-item-icon-color: var(--Primary);
}

.cdk-overlay-container .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: var(--Neutral-900);
}

.mat-calendar-body-cell-content {
  font-weight: var(--quantum-ref-font-weights-semi-bold);
}

.ng-scroll-content {
  position: relative;
}

@media only screen and (max-width: 37.5em) {
  .mobile-hide {
    display: none;
  }
}

.hidden {
  display: none !important;
}

.btn {
  overflow: hidden !important;
  text-overflow: ellipsis;
}

button.mat-mdc-button-base.mdc-button.mat-mdc-button,
.mat-mdc-outlined-button.mat-mdc-button-base.mdc-button.mdc-button--outlined,
.mat-mdc-unelevated-button.mat-mdc-button-base.mdc-button.mdc-button--unelevated {
  height: 3rem;
  padding: 0.75rem 1rem;
}

.mat-mdc-outlined-button:not(:disabled, .mat-warn) {
  --mdc-outlined-button-outline-color: var(--Outlined);
}

.mdc-button mat-icon {
  font-size: 1.75rem !important;
}

.mat-mdc-outlined-button[disabled][disabled],
.mat-mdc-button[disabled][disabled] {
  color: var(--Primary-Disabled);
  border-color: currentColor;
}

button.mdc-button.mat-mdc-outlined-button {
  border: 1px solid var(--Outlined);
  background-color: var(--Surface);
  --mdc-outlined-button-label-text-color: var(--Outlined);
}
button.mdc-button.mat-mdc-outlined-button:hover {
  background-color: var(--Outlined-Hover);
}
button.mdc-button.mat-mdc-outlined-button.mat-warn {
  color: var(--Danger);
  border: 1px solid var(--Danger);
}
button.mdc-button.mat-mdc-outlined-button.mat-warn:hover {
  color: var(--Danger-Hover);
  border: 1px solid var(--Danger-Hover);
}
button.mdc-button.mat-mdc-outlined-button.mat-accent {
  color: var(--Tonal);
  border: 1px solid var(--Tonal);
}
button.mdc-button.mat-mdc-outlined-button.mat-accent:hover {
  color: var(--Tonal-Hover);
  border: 1px solid var(--Tonal-Hover);
}

.mdc-button.mat-mdc-unelevated-button {
  color: var(--On-Primary);
}
.mdc-button.mat-mdc-unelevated-button:hover {
  background-color: var(--Primary-Hover);
}
.mdc-button.mat-mdc-unelevated-button.mat-accent {
  background-color: var(--Tonal);
  color: var(--On-Tonal);
}
.mdc-button.mat-mdc-unelevated-button.mat-accent:hover {
  background-color: var(--Tonal-Hover);
}
.mdc-button.mat-mdc-unelevated-button.mat-warn {
  background-color: var(--Danger);
  color: var(--On-Danger);
}
.mdc-button.mat-mdc-unelevated-button.mat-warn:hover {
  background-color: var(--Danger-Hover);
}
.mdc-button.mat-mdc-unelevated-button.mat-mdc-unelevated-button[disabled][disabled] {
  background-color: var(--Primary);
  color: var(--On-Primary);
  opacity: var(--Opacity-Disabled);
}

.mdc-button.mat-mdc-icon-button {
  color: var(--Primary);
}
.mdc-button.mat-mdc-icon-button:hover {
  background-color: var(--Primary-Disabled);
}
.mdc-button.mat-mdc-icon-button.mat-accent {
  background-color: var(--Tonal);
  color: var(--On-Tonal);
}
.mdc-button.mat-mdc-icon-button.mat-accent:hover {
  background-color: var(--Tonal-Hover);
}
.mdc-button.mat-mdc-icon-button.mat-warn {
  background-color: var(--Danger);
  color: var(--On-Danger);
}
.mdc-button.mat-mdc-icon-button.mat-warn:hover {
  background-color: var(--Danger-Hover);
}

.close.mat-mdc-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  line-height: 1.6em;
  min-width: auto;
}

.close.mat-mdc-icon-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  line-height: 1rem;
  min-width: auto;
}

.btn--warning {
  background-color: var(--Status-Error);
  color: var(--On-Status-Error);
}
.btn--warning:hover {
  background-color: var(--Status-Error-Hover);
}

.background__image, body.search-page--overlay {
  background-image: url("../../assets/images/page-backgrounds/main-background.png");
  background-color: var(--Neutral-300);
  background-position: center;
  background-size: cover;
}

.background__image--login, body.login-page--overlay {
  background-image: url("../../assets/images/page-backgrounds/login-background.jpg");
  background-color: var(--Neutral-300);
  background-position: center;
  background-size: cover;
}

.background__image--vendor-portal {
  background-image: url("../../assets/images/page-backgrounds/background-vendor-portal.png");
  background-color: var(--Neutral-300);
  background-position: center;
  background-size: cover;
}

.background__overlay--light, body.login-page--overlay {
  box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.4);
}

.background__overlay--medium, body.search-page--overlay {
  box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.6);
}

.background__overlay--dark {
  box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.8);
}

body.search-page--overlay {
  position: relative;
}

.mat-icon {
  height: auto !important;
  width: auto !important;
}

.error__icon {
  color: var(--Status-Error);
  font-size: 5rem;
}

.success__icon {
  color: var(--Primary);
  font-size: 5rem;
}

.mat-sort-header .mat-sort-header-content {
  text-align: left;
}

td.mat-mdc-cell:first-of-type {
  padding-inline-start: 24px;
}

th.mat-mdc-header-cell {
  font-size: 0.75rem;
  font-weight: bold;
}

th.mat-mdc-header-cell:first-of-type {
  padding-inline-start: 24px;
}

td.mat-mdc-cell:last-of-type {
  padding-inline-end: 24px;
}

.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  padding-top: 6px;
}

.mdc-text-field:not(.mdc-text-field--invalid, .mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline > * {
  border-color: var(--Primary) !important;
}

mat-datepicker-toggle > .mat-mdc-icon-button {
  --mdc-icon-button-icon-color: var(--Primary);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  --mdc-outlined-text-field-label-text-color: var(--quantum-on-surface-high-emphasis);
}

.mat-mdc-option .mat-icon,
.mat-mdc-option {
  vertical-align: middle;
}

.mat-mdc-text-field-wrapper {
  background-color: var(--Surface);
  border-radius: 0.5rem !important;
}

mat-paginator * .mat-mdc-text-field-wrapper {
  background-color: inherit;
}

.mat-mdc-form-field.mat-focused .mdc-floating-label mat-label {
  padding-block: 0.25rem;
  border-radius: 0.125rem;
  color: var(--Primary);
}

.mdc-menu-surface.mat-mdc-autocomplete-panel {
  padding: 0 !important;
}

.mat-mdc-paginator-icon {
  --mat-paginator-enabled-icon-color: var(--Primary);
  --mat-paginator-disabled-icon-color: var(--Primary-Disabled);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  font-size: 0.875rem;
  font-weight: 400;
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 0.875rem !important;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 0.875rem !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-container-color: var(--Surface);
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: block !important;
}

.mat-mdc-select {
  font-size: 0.875rem;
}

.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: var(--Primary);
  --mdc-switch-selected-handle-color: var(--Primary);
  --mdc-switch-selected-hover-state-layer-color: var(--Primary);
  --mdc-switch-selected-pressed-state-layer-color: var(--Primary);
  --mdc-switch-selected-focus-handle-color: var(--Primary);
  --mdc-switch-selected-hover-handle-color: var(--Primary);
  --mdc-switch-selected-pressed-handle-color: var(--Primary);
  --mdc-switch-selected-focus-track-color: var(--Primary-Disabled);
  --mdc-switch-selected-hover-track-color: var(--Primary-Disabled);
  --mdc-switch-selected-pressed-track-color: var(--Primary-Disabled);
  --mdc-switch-selected-track-color: var(--Primary-Disabled);
}

.mdc-switch__icon {
  display: none;
}

.dialog__container {
  padding: 1.5rem;
  display: block;
}
@media only screen and (max-width: 60em) {
  .dialog__container {
    padding: 1rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .dialog__container {
    padding: 0.75rem;
  }
}

.cdk-overlay-pane.mat-dialog--override {
  position: relative !important;
}

.mat-dialog--override {
  height: 100%;
}
.mat-dialog--override mat-dialog-container > :first-child {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.mat-dialog--override mat-dialog-container mat-dialog-content,
.mat-dialog--override mat-dialog-container div[mat-dialog-content] {
  flex-grow: 1;
  max-height: unset;
}
@media (max-width: 40rem) {
  .mat-dialog--override {
    width: 100%;
  }
}

.mat-mdc-dialog-container {
  box-shadow: 0 1.5rem 3rem var(--Primary-Shadow);
}