.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: var(--Primary);
  --mdc-switch-selected-handle-color: var(--Primary);
  --mdc-switch-selected-hover-state-layer-color: var(--Primary);
  --mdc-switch-selected-pressed-state-layer-color: var(--Primary);
  --mdc-switch-selected-focus-handle-color: var(--Primary);
  --mdc-switch-selected-hover-handle-color: var(--Primary);
  --mdc-switch-selected-pressed-handle-color: var(--Primary);
  --mdc-switch-selected-focus-track-color: var(--Primary-Disabled);
  --mdc-switch-selected-hover-track-color: var(--Primary-Disabled);
  --mdc-switch-selected-pressed-track-color: var(--Primary-Disabled);
  --mdc-switch-selected-track-color: var(--Primary-Disabled);
}

.mdc-switch__icon {
  display: none;
}
