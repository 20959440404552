// Change from text-align:center in data-table to keep field names and columns aligned
.mat-sort-header .mat-sort-header-content {
  text-align: left;
}

td.mat-mdc-cell:first-of-type {
  padding-inline-start: 24px;
}

th.mat-mdc-header-cell {
  font-size: 0.75rem;
  font-weight: bold;
}

th.mat-mdc-header-cell:first-of-type {
  padding-inline-start: 24px;
}

td.mat-mdc-cell:last-of-type {
  padding-inline-end: 24px;
}
