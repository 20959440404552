html,
body {
  background: var(--Background);
  font-family: var(--body-font-family);
  height: 100%;
  margin: 0;
  overflow-y: auto;
  padding: 0;
}

.page__container--fixed-width {
  width: 100%;
  max-width: 80rem;
  margin-inline: auto;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
