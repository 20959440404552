/* Colour definitions
   This file is now deprecated. 
   Please use the CSS variables from all-themes.css from the @quantum/tokens
   package for all component colors.
*/

:root {
  // Brand Colours
  --Midnight-Ocean: hsla(218, 29%, 22%, 1);
  --Blood-Orange: hwb(15 20% 0%);
  --Arctic-Blue: hsla(187, 75%, 95%, 1);
  --Sky-Blue: hsla(196, 100%, 47%, 1);
  --Sky-Blue-24: hsla(196, 100%, 24%, 1);
  --Blue-60: hsla(216, 82%, 57%, 1);

  --Navy-Blue: #000d69;

  // Deprecated Values

  --V-Dark-Grey: var(--Neutral-700);
  --V-Grey-Dark: var(--Neutral-500);
  --V-Grey: var(--quantum-on-surface-low-emphasis);
  --V-White: var(--Neutral-50);

  // Neutrals
  --Neutral-900: var(--quantum-on-surface-high-emphasis);
  --Neutral-700: var(--quantum-nav-surface);
  --Neutral-600: var(--quantum-nav-surface);
  --Neutral-500: var(--quantum-on-surface-medium-emphasis);
  --Neutral-300: var(--quantum-on-surface-low-emphasis);
  --Neutral-200: var(--quantum-neutral-press);
  --Neutral-100: var(--quantum-neutral-default);
  --Neutral-50: var(--quantum-surface-primary);

  --Opacity-Default: 100%;
  --Opacity-Disabled: 38%;

  // Primary
  --Primary-Shadow: var(--quantum-primary-shadow);
  --Primary-Pressed: var(--quantum-primary-press);
  --Primary-Hover: var(--quantum-primary-hover);
  --Primary: var(--quantum-primary-default);
  --Primary-Disabled: var(--quantum-primary-toggle);
  --On-Primary: var(--quantum-primary-on);

  // Tonal
  --Tonal-Shadow: var(--quantum-tonal-shadow);
  --Tonal-Pressed: var(--quantum-tonal-press);
  --Tonal-Hover: var(--quantum-tonal-hover);
  --Tonal: var(--quantum-tonal-default);
  --Tonal-Disabled: rgba(var(--quantum-tonal-default), 0.38);
  --On-Tonal: var(--quantum-tonal-on);

  // Outlined
  --Outlined-Shadow: var(--quantum-outlined-shadow);
  --Outlined-Pressed: var(--quantum-outlined-press);
  --Outlined-Hover: var(--quantum-outlined-hover);
  --Outlined: var(--quantum-outlined-on);

  // Danger
  --Danger-Shadow: var(--quantum-danger-shadow);
  --Danger-Pressed: var(--quantum-danger-press);
  --Danger-Hover: var(--quantum-danger-hover);
  --Danger: var(--quantum-danger-default);
  --On-Danger: var(--quantum-danger-on);
  --Danger-Surface: var(--quantum-surface-danger);

  --Navbar: var(--quantum-nav-surface);
  --On-Navbar: var(--quantum-nav-button-on);

  --Accent: var(--Tonal);
  --On-Accent: var(--On-Tonal);
  --Accent-Hover: var(--Tonal-Hover);

  --Background: var(--quantum-background);
  --On-Background: var(--quantum-on-surface-high-emphasis);

  --Surface: var(--quantum-surface-primary);
  --On-Surface: var(--quantum-on-surface-high-emphasis);

  --Collection-1: var(--Navy-Blue);
  --Collection-2: hsla(0, 0%, 49%, 100%);
  --Collection-3: hsla(196, 100%, 49%, 100%);
  --Collection-4: hsla(230, 100%, 49%, 100%);
  --Collection-5: hsla(162, 39%, 49%, 100%);

  --Status-Success: var(--quantum-success-default);
  --On-Status-Success: var(--quantum-success-on);
  --Status-Success-Hover: var(--quantum-success-hover);

  --Status-Warning: var(--quantum-warning-default);
  --On-Status-Warning: var(--quantum-warning-on);
  --Status-Warning-Hover: var(--quantum-warning-hover);

  --Status-Error: var(--quantum-danger-default);
  --On-Status-Error: var(--quantum-danger-on);
  --Status-Error-Hover: var(--quantum-danger-hover);

  --Low-Emphasis: var(--quantum-on-surface-low-emphasis);
  --Medium-Emphasis: var(--quantum-on-surface-medium-emphasis);
  --High-Emphasis: var(--quantum-on-surface-high-emphasis);

  --Disabled: var(--Neutral-300);
}
