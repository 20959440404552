@use 'abstracts/mixins';

.background__image {
  background-image: url('../../assets/images/page-backgrounds/main-background.png');
  background-color: var(--Neutral-300);
  background-position: center;
  background-size: cover;
}

.background__image--login {
  background-image: url('../../assets/images/page-backgrounds/login-background.jpg');
  background-color: var(--Neutral-300);
  background-position: center;
  background-size: cover;
}

.background__image--vendor-portal {
  background-image: url('../../assets/images/page-backgrounds/background-vendor-portal.png');
  background-color: var(--Neutral-300);
  background-position: center;
  background-size: cover;
}

.background__overlay--light {
  @include mixins.set_overlay(0.4);
}

.background__overlay--medium {
  @include mixins.set_overlay(0.6);
}

.background__overlay--dark {
  @include mixins.set_overlay(0.8);
}

body.login-page--overlay {
  @extend .background__image--login;
  @extend .background__overlay--light;
}

body.search-page--overlay {
  @extend .background__image;
  @extend .background__overlay--medium;
  position: relative;
}
