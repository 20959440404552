@import 'abstracts/breakpoints';

@mixin filterCount($block-start) {
  position: absolute;
  inset-block-start: $block-start;
  inset-inline-end: -1.25rem;
  background-color: var(--Primary);
  color: var(--V-White);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  width: 0.8125rem;
  height: 0.8125rem;
  border-radius: 0.5rem;
  font-weight: 600;
  line-height: 0.5rem;
}

@mixin set_overlay($alpha) {
  box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, $alpha);
}

// MEDIA QUERY MANAGER
/*
  0 - 600px: Extra small
  600-959px: Small
  960-1279px: Medium
  1280+: Large,  is where our normal styles apply

  $breakpoint argument choices:visited
  - xs
  - s
  - m
  - l

  1em=16px
  */

@mixin respond($breakpoint) {
  @if $breakpoint ==xs {
    @media only screen and (max-width: $screen-XS) {
      //600px
      @content;
    }
  }

  @if $breakpoint ==s {
    @media only screen and (max-width: $screen-S) {
      //959px
      @content;
    }
  }

  @if $breakpoint ==m {
    @media only screen and (max-width: $screen-M) {
      //1279px
      @content;
    }
  }

  @if $breakpoint ==l {
    @media only screen and (min-width: $screen-L) {
      //1279px +
      @content;
    }
  }

  @if $breakpoint ==xl {
    @media only screen and (min-width: $screen-XL) {
      //1400px +
      @content;
    }
  }
}
