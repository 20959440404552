@use '@angular/material' as mat;
@import '@quantum/tokens/scss/typography.scss';
@import '../abstracts/mixins';
@import '../abstracts/typography-levels';

$fontConfig-desktop: mat.define-typography-config(
  $font-family: $quantum-sys-typography-body-large-regular-font-family,
  $headline-5: $headline-5-desktop,
  $headline-6: $headline-6-desktop,
  $subtitle-1: $subtitle-1-desktop,
  $subtitle-2: $subtitle-2-desktop,
  $body-1:
    mat.define-typography-level(
      $quantum-sys-typography-body-large-regular-font-size,
      $quantum-sys-typography-body-large-regular-line-height,
      $quantum-sys-typography-body-large-regular-font-weight,
      $quantum-sys-typography-body-large-regular-font-family,
      $letter-spacing: -0.2px
    ),
  $body-2:
    mat.define-typography-level(
      $quantum-sys-typography-body-medium-regular-font-size,
      $quantum-sys-typography-body-medium-regular-line-height,
      $quantum-sys-typography-body-medium-regular-font-weight,
      $quantum-sys-typography-body-medium-regular-font-family,
      $letter-spacing: -0.2px
    ),
  $caption:
    mat.define-typography-level(
      $quantum-sys-typography-caption-font-size,
      $quantum-sys-typography-caption-line-height,
      $quantum-sys-typography-caption-font-family,
      $quantum-sys-typography-caption-font-family,
      $letter-spacing: -0.2px
    ),
  $button:
    mat.define-typography-level(
      $quantum-sys-typography-button-font-size,
      $quantum-sys-typography-button-line-height,
      $quantum-sys-typography-button-font-weight,
      $quantum-sys-typography-button-font-family,
      $letter-spacing: -0.2px
    ),
);

$fontConfig-tablet: mat.define-typography-config(
  $font-family: $quantum-sys-typography-body-large-regular-font-family,
  $headline-5: $headline-5-tablet,
  $headline-6: $headline-6-tablet,
  $subtitle-1: $subtitle-1-tablet,
  $subtitle-2: $subtitle-2-tablet,
  $body-1:
    mat.define-typography-level(
      $quantum-sys-typography-body-large-regular-font-size,
      $quantum-sys-typography-body-large-regular-line-height,
      $quantum-sys-typography-body-large-regular-font-weight,
      $quantum-sys-typography-body-large-regular-font-family,
      $letter-spacing: -0.2px
    ),
  $body-2:
    mat.define-typography-level(
      $quantum-sys-typography-body-medium-regular-font-size,
      $quantum-sys-typography-body-medium-regular-line-height,
      $quantum-sys-typography-body-medium-regular-font-weight,
      $quantum-sys-typography-body-medium-regular-font-family,
      $letter-spacing: -0.2px
    ),
  $caption:
    mat.define-typography-level(
      $quantum-sys-typography-caption-font-size,
      $quantum-sys-typography-caption-line-height,
      $quantum-sys-typography-caption-font-family,
      $quantum-sys-typography-caption-font-family,
      $letter-spacing: -0.2px
    ),
  $button:
    mat.define-typography-level(
      $quantum-sys-typography-button-font-size,
      $quantum-sys-typography-button-line-height,
      $quantum-sys-typography-button-font-weight,
      $quantum-sys-typography-button-font-family,
      $letter-spacing: -0.2px
    ),
);

$fontConfig-mobile: mat.define-typography-config(
  $font-family: $quantum-sys-typography-body-large-regular-font-family,
  $headline-5: $headline-5-mobile,
  $headline-6: $headline-6-mobile,
  $subtitle-1: $subtitle-1-mobile,
  $subtitle-2: $subtitle-2-mobile,
  $body-1:
    mat.define-typography-level(
      $quantum-sys-typography-body-large-regular-font-size,
      $quantum-sys-typography-body-large-regular-line-height,
      $quantum-sys-typography-body-large-regular-font-weight,
      $quantum-sys-typography-body-large-regular-font-family,
      $letter-spacing: -0.2px
    ),
  $body-2:
    mat.define-typography-level(
      $quantum-sys-typography-body-medium-regular-font-size,
      $quantum-sys-typography-body-medium-regular-line-height,
      $quantum-sys-typography-body-medium-regular-font-weight,
      $quantum-sys-typography-body-medium-regular-font-family,
      $letter-spacing: -0.2px
    ),
  $caption:
    mat.define-typography-level(
      $quantum-sys-typography-caption-font-size,
      $quantum-sys-typography-caption-line-height,
      $quantum-sys-typography-caption-font-family,
      $quantum-sys-typography-caption-font-family,
      $letter-spacing: -0.2px
    ),
  $button:
    mat.define-typography-level(
      $quantum-sys-typography-button-font-size,
      $quantum-sys-typography-button-line-height,
      $quantum-sys-typography-button-font-weight,
      $quantum-sys-typography-button-font-family,
      $letter-spacing: -0.2px
    ),
);

// Compute font config
@include mat.all-component-typographies($fontConfig-desktop);
@include mat.core();

@include respond(m) {
  @include mat.all-component-typographies($fontConfig-tablet);
}

@include respond(s) {
  @include mat.all-component-typographies($fontConfig-mobile);
}

.quantum-page-heading,
h1.quantum-page-heading {
  font-family: var(--quantum-sys-typography-heading-desktop-page-heading-font-family);
  font-weight: var(--quantum-sys-typography-heading-desktop-page-heading-font-weight);
  line-height: var(--quantum-sys-typography-heading-desktop-page-heading-line-height);
  font-size: var(--quantum-sys-typography-heading-desktop-page-heading-font-size);
  text-decoration: var(--quantum-sys-typography-heading-desktop-page-heading-text-decoration);

  @include respond(m) {
    font-family: var(--quantum-sys-typography-heading-tablet-page-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-tablet-page-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-tablet-page-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-tablet-page-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-tablet-page-heading-text-decoration);
  }

  @include respond(xs) {
    font-family: var(--quantum-sys-typography-heading-mobile-page-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-mobile-page-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-mobile-page-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-mobile-page-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-mobile-page-heading-text-decoration);
  }
}

.quantum-page-subheading,
h3.quantum-page-subheading {
  font-family: var(--quantum-sys-typography-heading-desktop-page-sub-heading-font-family);
  font-weight: var(--quantum-sys-typography-heading-desktop-page-sub-heading-font-weight);
  line-height: var(--quantum-sys-typography-heading-desktop-page-sub-heading-line-height);
  font-size: var(--quantum-sys-typography-heading-desktop-page-sub-heading-font-size);
  text-decoration: var(--quantum-sys-typography-heading-desktop-sub-heading-text-decoration);

  @include respond(m) {
    font-family: var(--quantum-sys-typography-heading-tablet-page-sub-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-tablet-page-sub-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-tablet-page-sub-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-tablet-page-sub-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-tablet-page-sub-heading-text-decoration);
  }

  @include respond(xs) {
    font-family: var(--quantum-sys-typography-heading-mobile-page-sub-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-mobile-page-sub-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-mobile-page-sub-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-mobile-page-sub-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-mobile-page-sub-heading-text-decoration);
  }
}

.quantum-section-heading,
h2.quantum-section-heading {
  font-family: var(--quantum-sys-typography-heading-desktop-section-heading-font-family);
  font-weight: var(--quantum-sys-typography-heading-desktop-section-heading-font-weight);
  line-height: var(--quantum-sys-typography-heading-desktop-section-heading-line-height);
  font-size: var(--quantum-sys-typography-heading-desktop-section-heading-font-size);
  text-decoration: var(--quantum-sys-typography-heading-desktop-section-heading-text-decoration);

  @include respond(m) {
    font-family: var(--quantum-sys-typography-heading-tablet-section-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-tablet-section-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-tablet-section-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-tablet-section-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-tablet-section-heading-text-decoration);
  }

  @include respond(xs) {
    font-family: var(--quantum-sys-typography-heading-mobile-section-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-mobile-section-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-mobile-section-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-mobile-section-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-mobile-section-heading-text-decoration);
  }
}

.quantum-section-subheading,
h4.quantum-section-subheading {
  font-family: var(--quantum-sys-typography-heading-desktop-section-sub-heading-font-family);
  font-weight: var(--quantum-sys-typography-heading-desktop-section-sub-heading-font-weight);
  line-height: var(--quantum-sys-typography-heading-desktop-section-sub-heading-line-height);
  font-size: var(--quantum-sys-typography-heading-desktop-section-sub-heading-font-size);
  text-decoration: var(--quantum-sys-typography-heading-desktop-section-sub-heading-text-decoration);

  @include respond(m) {
    font-family: var(--quantum-sys-typography-heading-tablet-section-sub-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-tablet-section-sub-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-tablet-section-sub-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-tablet-section-sub-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-tablet-section-sub-heading-text-decoration);
  }

  @include respond(xs) {
    font-family: var(--quantum-sys-typography-heading-mobile-section-subheading-font-family);
    font-weight: var(--quantum-sys-typography-heading-mobile-section-subheading-font-weight);
    line-height: var(--quantum-sys-typography-heading-mobile-section-subheading-line-height);
    font-size: var(--quantum-sys-typography-heading-mobile-section-subheading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-mobile-section-subheading-text-decoration);
  }
}

.quantum-card-heading,
h2.quantum-card-heading {
  font-family: var(--quantum-sys-typography-heading-desktop-card-heading-font-family);
  font-weight: var(--quantum-sys-typography-heading-desktop-card-heading-font-weight);
  line-height: var(--quantum-sys-typography-heading-desktop-card-heading-line-height);
  font-size: var(--quantum-sys-typography-heading-desktop-card-heading-font-size);
  text-decoration: var(--quantum-sys-typography-heading-desktop-card-heading-text-decoration);

  @include respond(m) {
    font-family: var(--quantum-sys-typography-heading-tablet-card-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-tablet-card-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-tablet-card-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-tablet-card-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-tablet-card-heading-text-decoration);
  }

  @include respond(xs) {
    font-family: var(--quantum-sys-typography-heading-mobile-card-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-mobile-card-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-mobile-card-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-mobile-card-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-mobile-card-heading-text-decoration);
  }
}

.quantum-card-subheading,
h3.quantum-card-subheading {
  font-family: var(--quantum-sys-typography-heading-desktop-card-sub-heading-font-family);
  font-weight: var(--quantum-sys-typography-heading-desktop-card-sub-heading-font-weight);
  line-height: var(--quantum-sys-typography-heading-desktop-card-sub-heading-line-height);
  font-size: var(--quantum-sys-typography-heading-desktop-card-sub-heading-font-size);
  text-decoration: var(--quantum-sys-typography-heading-desktop-card-sub-heading-text-decoration);

  @include respond(m) {
    font-family: var(--quantum-sys-typography-heading-tablet-card-sub-heading-font-family);
    font-weight: var(--quantum-sys-typography-heading-tablet-card-sub-heading-font-weight);
    line-height: var(--quantum-sys-typography-heading-tablet-card-sub-heading-line-height);
    font-size: var(--quantum-sys-typography-heading-tablet-card-sub-heading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-tablet-card-sub-heading-text-decoration);
  }

  @include respond(xs) {
    font-family: var(--quantum-sys-typography-heading-mobile-card-subheading-font-family);
    font-weight: var(--quantum-sys-typography-heading-mobile-card-subheading-font-weight);
    line-height: var(--quantum-sys-typography-heading-mobile-card-subheading-line-height);
    font-size: var(--quantum-sys-typography-heading-mobile-card-subheading-font-size);
    text-decoration: var(--quantum-sys-typography-heading-mobile-card-subheading-text-decoration);
  }
}

.quantum-body-large-regular {
  font-family: var(--quantum-sys-typography-body-large-regular-font-family);
  font-weight: var(--quantum-sys-typography-body-large-regular-font-weight);
  line-height: var(--quantum-sys-typography-body-large-regular-line-height);
  font-size: var(--quantum-sys-typography-body-large-regular-font-size);
  text-decoration: var(--quantum-sys-typography-body-large-regular-text-decoration);
}

.quantum-body-large-medium {
  font-family: var(--quantum-sys-typography-body-large-medium-font-family);
  font-weight: var(--quantum-sys-typography-body-large-medium-font-weight);
  line-height: var(--quantum-sys-typography-body-large-medium-line-height);
  font-size: var(--quantum-sys-typography-body-large-medium-font-size);
  text-decoration: var(--quantum-sys-typography-body-large-medium-text-decoration);
}

.quantum-body-large-bold {
  font-family: var(--quantum-sys-typography-body-large-bold-font-family);
  font-weight: var(--quantum-sys-typography-body-large-bold-font-weight);
  line-height: var(--quantum-sys-typography-body-large-bold-line-height);
  font-size: var(--quantum-sys-typography-body-large-bold-font-size);
  text-decoration: var(--quantum-sys-typography-body-large-bold-text-decoration);
}

.quantum-body-large-link {
  font-family: var(--quantum-sys-typography-body-large-link-font-family);
  font-weight: var(--quantum-sys-typography-body-large-link-font-weight);
  line-height: var(--quantum-sys-typography-body-large-link-line-height);
  font-size: var(--quantum-sys-typography-body-large-link-font-size);
  text-decoration: var(--quantum-sys-typography-body-large-link-text-decoration);
}

.quantum-body-medium-regular {
  font-family: var(--quantum-sys-typography-body-medium-regular-font-family);
  font-weight: var(--quantum-sys-typography-body-medium-regular-font-weight);
  line-height: var(--quantum-sys-typography-body-medium-regular-line-height);
  font-size: var(--quantum-sys-typography-body-medium-regular-font-size);
  text-decoration: var(--quantum-sys-typography-body-medium-regular-text-decoration);
}

.quantum-body-medium-medium {
  font-family: var(--quantum-sys-typography-body-medium-medium-font-family);
  font-weight: var(--quantum-sys-typography-body-medium-medium-font-weight);
  line-height: var(--quantum-sys-typography-body-medium-medium-line-height);
  font-size: var(--quantum-sys-typography-body-medium-medium-font-size);
  text-decoration: var(--quantum-sys-typography-body-medium-medium-text-decoration);
}

.quantum-body-medium-bold {
  font-family: var(--quantum-sys-typography-body-medium-bold-font-family);
  font-weight: var(--quantum-sys-typography-body-medium-bold-font-weight);
  line-height: var(--quantum-sys-typography-body-medium-bold-line-height);
  font-size: var(--quantum-sys-typography-body-medium-bold-font-size);
  text-decoration: var(--quantum-sys-typography-body-medium-bold-text-decoration);
}

.quantum-body-medium-link {
  font-family: var(--quantum-sys-typography-body-medium-link-font-family);
  font-weight: var(--quantum-sys-typography-body-medium-link-font-weight);
  line-height: var(--quantum-sys-typography-body-medium-link-line-height);
  font-size: var(--quantum-sys-typography-body-medium-link-font-size);
  text-decoration: var(--quantum-sys-typography-body-medium-link-text-decoration);
}

.quantum-body-small-regular {
  font-family: var(--quantum-sys-typography-body-small-regular-font-family);
  font-weight: var(--quantum-sys-typography-body-small-regular-font-weight);
  line-height: var(--quantum-sys-typography-body-small-regular-line-height);
  font-size: var(--quantum-sys-typography-body-small-regular-font-size);
  text-decoration: var(--quantum-sys-typography-body-small-regular-text-decoration);
}

.quantum-body-small-medium {
  font-family: var(--quantum-sys-typography-body-small-medium-font-family);
  font-weight: var(--quantum-sys-typography-body-small-medium-font-weight);
  line-height: var(--quantum-sys-typography-body-small-medium-line-height);
  font-size: var(--quantum-sys-typography-body-small-medium-font-size);
  text-decoration: var(--quantum-sys-typography-body-small-medium-text-decoration);
}

.quantum-body-small-bold {
  font-family: var(--quantum-sys-typography-body-small-bold-font-family);
  font-weight: var(--quantum-sys-typography-body-small-bold-font-weight);
  line-height: var(--quantum-sys-typography-body-small-bold-line-height);
  font-size: var(--quantum-sys-typography-body-small-bold-font-size);
  text-decoration: var(--quantum-sys-typography-body-small-bold-text-decoration);
}

.quantum-body-small-link {
  font-family: var(--quantum-sys-typography-body-small-link-font-family);
  font-weight: var(--quantum-sys-typography-body-small-link-font-weight);
  line-height: var(--quantum-sys-typography-body-small-link-line-height);
  font-size: var(--quantum-sys-typography-body-small-link-font-size);
  text-decoration: var(--quantum-sys-typography-body-small-link-text-decoration);
}

.quantum-button {
  font-size: var(--quantum-sys-typography-button-font-size);
  line-height: var(--quantum-sys-typography-button-line-height);
  font-weight: var(--quantum-sys-typography-button-font-weight);
  font-family: var(--quantum-sys-typography-button-font-family);
  text-decoration: var(--quantum-sys-typography-button-text-decoration);
  letter-spacing: -0.2px;
}

.quantum-caption {
  font-family: var(--quantum-sys-typography-caption-font-family);
  font-weight: var(--quantum-sys-typography-caption-font-weight);
  line-height: var(--quantum-sys-typography-caption-line-height);
  font-size: var(--quantum-sys-typography-caption-font-size);
  text-decoration: var(--quantum-sys-typography-caption-text-decoration);
}

.quantum-badge {
  font-family: var(--quantum-sys-typography-badge-font-family);
  font-weight: var(--quantum-sys-typography-badge-font-weight);
  line-height: var(--quantum-sys-typography-badge-line-height);
  font-size: var(--quantum-sys-typography-badge-font-size);
  text-decoration: var(--quantum-sys-typography-badge-text-decoration);
}
