@use 'abstracts/mixins';

.dialog__container {
  padding: 1.5rem;
  display: block;

  @include mixins.respond(s) {
    padding: 1rem;
  }

  @include mixins.respond(xs) {
    padding: 0.75rem;
  }
}

.cdk-overlay-pane.mat-dialog--override {
  position: relative !important;
}

.mat-dialog--override {
  height: 100%;

  mat-dialog-container {
    > :first-child {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    mat-dialog-content,
    div[mat-dialog-content] {
      flex-grow: 1;
      max-height: unset;
    }
  }

  @media (max-width: 40rem) {
    width: 100%;
  }
}

.mat-mdc-dialog-container {
  box-shadow: 0 1.5rem 3rem var(--Primary-Shadow);
}
