.mat-icon {
  height: auto !important;
  width: auto !important;
}

.error__icon {
  color: var(--Status-Error);
  font-size: 5rem;
}

.success__icon {
  color: var(--Primary);
  font-size: 5rem;
}
